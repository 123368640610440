<template>
  <div>
    <h1 class="mr-sm-4 header-tablepage mb-3">Check rich menu</h1>
    <div class="title-tabs">
      <b-row calss="">
        <b-col md="6" class="text-left d-flex">
          <div>Search User's Rich Menu</div>
        </b-col>
      </b-row>
    </div>
    <div class="p-3 bg-white">
      <div class="input-action">
        <InputText
          textFloat="Telephone"
          :value="displayValue"
          placeholder="Telephone"
          type="text"
          readonly
          @inputClick="handleCustomer"
        />
        <div class="input-action-icon">
          <b-icon
            stacked
            icon="search"
            scale="0.5"
            variant="grey"
            @click="handleCustomer"
          ></b-icon>
        </div>
      </div>
      <b-col cols="12" lg="8" xl="6">
        <div class="mb-1">Preview</div>
        <div class="preview-container">
          <div class="wrap-panel">
            <div class="layout">
              <div class="rich-menu-panel">
                <b-collapse id="my-collapse" visible>
                  <div v-if="!isLoading" class="rich-menu">
                    <b-img
                      :src="form.imageUri || form.default_image_layout"
                      ref="menu-image"
                    ></b-img>
                    <div class="menu">
                      <div
                        v-for="cell of form.lineRichTemplateAction"
                        :key="cell.id"
                        :class="`menu-box ${
                          form.highlight == cell.sort ? 'highlight' : ''
                        }`"
                        :style="{
                          top: `${cell.top}%`,
                          left: `${cell.left}%`,
                          width: `${cell.widthPercent}%`,
                          height: `${cell.heightPercent}%`,
                        }"
                      >
                        <div class="sort-menu">
                          {{ convertText(cell.sort) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <OtherLoading v-else />
                </b-collapse>
                <div v-b-toggle:my-collapse class="rich-menu-bottom">
                  <font-awesome-icon icon="bars"></font-awesome-icon>
                  <div class="mx-auto">
                    {{ form.chatBarText }}
                    <font-awesome-icon icon="caret-down"></font-awesome-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <ModalCustomerList ref="CustomerModal" @select="handleSelectedCustomer" />
    </div>
  </div>
</template>

<script>
import ModalCustomerList from "@/components/ticket/activity/ModalCustomerList";
export default {
  components: {
    ModalCustomerList,
  },
  data() {
    return {
      displayValue: "",
      value: "",
      form: {
        chatBarText: "",
        lineRichTemplateAction: [],
        default_image_layout: "",
        imageUri: "",
      },
      isLoading: false,
    };
  },
  methods: {
    handleCustomer(index) {
      this.$refs[`CustomerModal`].show();
    },
    handleSelectedCustomer(customer) {
      this.displayValue = [customer.name, customer.telephone]
        .filter((el) => el)
        .join(", ");

      this.getUserLineRichMenu(customer.social_id);
      this.$forceUpdate();
    },
    async getUserLineRichMenu(user_guid) {
      this.$bus.$emit("showLoading");
      this.isLoading = true;
      const res = await this.axios(`/lineRich/getUserRichMenu/${user_guid}`);
      if (res.data.result == 0)
        this.errorAlert(res.data.message),
          (this.displayValue = ""),
          (this.form = "");
      else this.form = res.data.detail;
      this.isLoading = false;
      this.$bus.$emit("hideLoading");
    },
    convertText(a) {
      let Alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      return Alphabet[a - 1];
    },
  },
};
</script>

<style lang="scss">
.input-action {
  position: relative;
  width: 100%;

  ::v-deep .input-custom input {
    cursor: pointer !important;
  }

  .input-action-icon {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 27px;
    right: 5px;
    width: 32px;
    height: 32px;
    background-color: inherit;
    color: var(--primary-color);
  }
}
.previewBox {
  width: 40%;
}
.rich-menu {
  overflow: hidden;
  position: relative;
}
.menu {
  > div {
    position: absolute;
    top: 0;
    border: 2px solid green;
    height: auto;
    display: flex;

    align-items: center;
    justify-content: center;
    width: 100%;
    &.highlight {
      background: rgba(0, 128, 0, 0.329);
    }
  }
  .sort-menu {
    width: auto;
    color: white;
    font-size: 42px;
    font-weight: bold;
    position: absolute;
    // top: calc(124px + -75%);
    // left: calc(185px - 25%);
    // transform: translate();
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
}
.text-link {
  text-decoration: underline;
}
.border-preview {
  border: 2px dashed #979797;
  padding: 2rem;
}
.wrap-panel {
  position: relative;
  width: 70%;
  max-width: 370px;
}
.layout {
  width: 100%;
  /* border-radius: 0.75rem; */
  position: relative;
  overflow: hidden;
  height: 100%;
  aspect-ratio: 9 / 16;
  /*
  background: gray; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: whitesmoke;
  /* justify-content: space-between; */
}
.preview-container {
  display: flex;
  justify-content: center;
  /* border: 1px dashed whitesmoke; */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='5' stroke-dasharray='4%2c15' stroke-dashoffset='27' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 0.75rem;
}
.navbar {
  height: 80px;
  width: 100%;
  justify-content: center;
  background-color: var(--theme-navigation-bar-color);
  transition: background-color 0.1s linear;
  box-shadow: 1px 1px 5px -3px black;
}

.previewBox {
  width: 40%;
}
.rich-menu {
  overflow: hidden;
  position: relative;
}
.menu {
  > div {
    position: absolute;
    top: 0;
    border: 2px solid green;
    height: auto;
    display: flex;

    align-items: center;
    justify-content: center;
    width: 100%;
    &.highlight {
      background: rgba(0, 128, 0, 0.329);
    }
  }
  .sort-menu {
    width: auto;
    color: white;
    font-size: 42px;
    font-weight: bold;
    position: absolute;
    // top: calc(124px + -75%);
    // left: calc(185px - 25%);
    // transform: translate();
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
}
.rich-menu-panel {
  position: absolute;
  bottom: 0;
  width: 100%;
  img {
    width: 100%;
  }
}
.rich-menu-bottom {
  width: 100%;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 10px;
  background-color: white;
}
</style>
