<template>
  <b-sidebar
    :id="id"
    :visible="isShowSidebar"
    aria-labelledby="sidebar-ticket"
    shadow
    right
    :lazy="true"
    backdrop
    :no-enforce-focus="true"
    width="85vw"
    @hidden="hide"
  >
    <template #header>
      <b-row class="header-filter no-gutters">
        <b-col class="text-filter text-black"> Add Dependent Field </b-col>
      </b-row>
    </template>
    <div class="p-3">
      <b-row>
        <b-col sm="12" md="12" lg="6">
          <div class="text-black font-weight-bold mb-3">For Admin</div>
          <b-form-checkbox
            v-model="form.is_require_submit"
            :checked="form.is_require_submit"
            :value="1"
            :unchecked-value="0"
            @change="form.is_require_submit = $event"
            :disabled="
              Boolean(form.is_default_field) ||
              [10, 11, 13, 14].includes(form.field_type_id)
            "
            class="mb-1"
            >Required when submitting the ticket</b-form-checkbox
          >
          <b-form-checkbox
            v-model="form.is_require_close"
            :checked="form.is_require_close"
            :value="1"
            :unchecked-value="0"
            :disabled="
              Boolean(form.is_default_field) ||
              [10, 11, 13, 14].includes(form.field_type_id)
            "
            class="mb-1"
            @change="form.is_require_close = $event"
            >Required when closing the ticket</b-form-checkbox
          >
        </b-col>
        <b-col sm="12" md="12" lg="6">
          <div class="text-black font-weight-bold mb-3">For Customers</div>
          <b-form-checkbox
            v-model="form.is_customer_view"
            :checked="form.is_customer_view"
            :value="1"
            :unchecked-value="0"
            :disabled="
              Boolean(form.is_default_field) ||
              [10, 11, 13, 14].includes(form.field_type_id)
            "
            @change="form.is_customer_view = $event"
            class="mb-1"
            >Can View</b-form-checkbox
          >
          <b-form-checkbox
            v-model="form.is_customer_edit"
            :checked="form.is_customer_edit"
            :value="1"
            :unchecked-value="0"
            :disabled="
              Boolean(form.is_default_field) ||
              [10, 11, 13, 14].includes(form.field_type_id)
            "
            class="mb-1"
            @change="form.is_customer_edit = $event"
            >Can Edit</b-form-checkbox
          >
          <b-form-checkbox
            v-model="form.is_customer_require_submit"
            :checked="form.is_customer_require_submit"
            :value="1"
            :unchecked-value="0"
            :disabled="
              Boolean(form.is_default_field) ||
              [10, 11, 13, 14].includes(form.field_type_id)
            "
            class="mb-1"
            @change="form.is_customer_require_submit = $event"
            >Required when submitting the ticket</b-form-checkbox
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12" lg="6">
          <InputSelect
            :class="['text-float-normal question-name-input margin-input-5']"
            textFloat="Choose a field type"
            title="Field Type"
            name="type"
            isRequired
            :disabled="!isEditable"
            v-model="form.field_type_id"
            v-bind:options="fieldTypes"
            @onDataChange="changeFieldType"
            valueField="id"
            textField="name"
          />
        </b-col>
      </b-row>
      <b-row v-if="![10, 11, 13, 14].includes(form.field_type_id)">
        <b-col sm="12" md="12" lg="6">
          <InputText
            isRequired
            :class="[
              'text-float-normal question-name-input margin-input-5',
              isSubmit && !form.label_admin ? 'error' : '',
            ]"
            textFloat="Label For Admin"
            v-model="form.label_admin"
            :disabled="!isEditable"
            placeholder="Label For Admin"
            type="text"
            :v="$v.form.label_admin"
        /></b-col>
        <b-col sm="12" md="12" lg="6">
          <InputText
            isRequired
            :class="[
              'text-float-normal question-name-input margin-input-5',
              isSubmit && !form.label_customer ? 'error' : '',
            ]"
            textFloat="Label For Customer"
            v-model="form.label_customer"
            :disabled="!isEditable"
            placeholder="Label For Customer"
            type="text"
            :v="$v.form.label_customer"
          />
        </b-col>
      </b-row>
      <b-row v-if="![10, 11, 13, 14].includes(form.field_type_id)">
        <b-col sm="12" md="12" lg="6">
          <InputText
            :class="[
              'text-float-normal question-name-input margin-input-5',
              isSubmit && !form.validate_text_admin ? 'error' : '',
            ]"
            textFloat="Validate text"
            v-model="form.validate_text_admin"
            placeholder="Validate text"
            :disabled="!isEditable"
            type="text"
        /></b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12" lg="6">
          <InputText
            isRequired
            :class="[
              'text-float-normal question-name-input margin-input-5',
              isSubmit && !form.name ? 'error' : '',
            ]"
            textFloat="Name"
            v-model="form.name"
            :disabled="Boolean(form.is_default_field) || !isEditable"
            placeholder="Name"
            type="text"
            :v="$v.form.name"
        /></b-col>
      </b-row>
      <b-row v-if="[10, 11].includes(form.field_type_id)">
        <b-col sm="12" md="12" lg="6">
          <InputText
            :class="[
              'text-float-normal question-name-input margin-input-5',
              isSubmit && !form.label_admin ? 'error' : '',
            ]"
            isRequired
            textFloat="Question"
            v-model="form.label_admin"
            placeholder="Question"
            type="text"
            :v="$v.form.label_admin"
            :isValidate="$v.form.label_admin.$error"
        /></b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <FieldTextDetail
            v-if="form.field_type_id == 1"
            :item="form"
            :isEditable="isEditable"
            input-class="custom-text-input" />

          <FieldChoiceDetail
            v-if="form.field_type_id >= 2 && form.field_type_id <= 4"
            :item="form"
            :isEditable="isEditable" />
          <DateInput
            v-if="form.field_type_id == 5"
            class="disabled"
            :disabled="true" />

          <ModalSelectBranch
            v-if="form.field_type_id == 6 && form.branchList.length"
            :list="form.branchList"
            @selectBranch="selectBranch"
            :field="form"
            text="Select Branch"
            :id="`${form.id}`"
            :isEditable="isEditable"
            :BranchSelectList="form.select_branch || []" />
          <Date v-if="form.field_type_id == 7" class="" />

          <InputTextArea
            v-if="form.field_type_id == 12"
            textFloat=""
            type="text"
            class="mt-3 custom-input"
            placeholder=""
            rows="3"
        /></b-col>
      </b-row>

      <div
        v-for="(item, index) in form.field_dependent"
        :key="`field_dependent-${depth}-${item.id}-${index}`"
        class="dependent-form"
        :class="[{ 'is-first': isFirstOfType(item.depen_on_choice_id, item) }]"
      >
        <template v-if="isFirstOfType(item.depen_on_choice_id, item)">
          <div class="text-black font-weight-bold mb-1">
            Field Value #{{ item.seq }}
          </div>

          <InputSelect
            :class="['text-float-normal question-name-input margin-input-5']"
            textFloat="If value is"
            title="Field Type"
            name="type"
            isRequired
            :value="item.depen_on_choice_id"
            v-bind:options="form.field_choice"
            valueField="id"
            textField="name"
            :disabled="!item.isNew"
            @input="onFieldTypeChange($event, index, item.depen_on_choice_id)"
          >
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled
                >-- Select Field Type --</b-form-select-option
              >
            </template>
          </InputSelect>
        </template>

        <div class="pb-2">
          <label
            class="main-label"
            v-if="isFirstOfType(item.depen_on_choice_id, item)"
            >Then show this fields</label
          >

          <multiselect
            v-model="item.ticket_field_id"
            :options="
              getChoice(
                dependentFields,
                item.depen_on_choice_id,
                item.ticket_field_id
              )
            "
            placeholder="Select field"
            trackBy="id"
            label="label_admin"
            :searchable="false"
            :hideSelected="true"
            :class="[
              $v.form.field_dependent.$each.$iter[index].ticket_field_id.$error
                ? 'error'
                : '',
            ]"
            @select="($event) => onFieldChange($event, index)"
          >
            <template #option="{ option }">
              <div class="question-card-title">
                {{ option.disabled }}
                <div class="text-black">{{ option.label_admin }}:</div>
                <div class="type-box">
                  {{ questionTypeText(option.field_type_id) }}
                </div>
                <div class="text-light-grey">
                  {{ labelDescription(option) }}
                </div>
              </div>
            </template>
            <template #singleLabel="{ option }">
              <div class="question-card-title">
                <div class="text-black">
                  {{ getSelectedField(option)?.label_admin }}:
                </div>
                <div class="type-box">
                  {{
                    questionTypeText(getSelectedField(option)?.field_type_id)
                  }}
                </div>
                <div class="text-light-grey">
                  {{ labelDescription(getSelectedField(option)) }}
                </div>
              </div>
            </template>
          </multiselect>

          <div
            v-if="
              $v.form.field_dependent.$each.$iter[index].ticket_field_id.$error
            "
          >
            <span class="text-error">{{
              item.validate_text_admin || "Please Select."
            }}</span>
          </div>
        </div>

        <div v-if="isLastOfType(item.depen_on_choice_id, item)" class="pb-2">
          <u class="pointer" @click="addField(item)">Add field</u>
        </div>
      </div>
      <div v-if="isDependentField()" class="py-2">
        <u class="pointer" @click="addDependentField()">Add new field value</u>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            id="handle-search-filter"
            class="text-body rounded-pill btn-main"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import Date from "@/components/form/preview/Date";
import DateInput from "@/components/form/preview/DateInput";
import ModalSelectBranch from "@/components/ModalSelectBranch";
import FieldChoiceDetail from "./FieldChoiceDetail";
import FieldTextDetail from "./FieldTextDetail";
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
import InputSelect from "@/components/inputs/InputSelect";
import { requiredIf, required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Date,
    DateInput,
    ModalSelectBranch,
    FieldChoiceDetail,
    FieldTextDetail,
    InputText,
    InputTextArea,
    InputSelect,
    Multiselect,
  },
  props: {
    id: {
      required: false,
      default: "sidebarTicket",
    },
    item: {
      required: true,
      type: Object,
    },
    actionType: {
      required: true,
      type: Number,
    },
    fieldTypes: {
      required: true,
      type: Array,
    },
    isDependent: {
      required: true,
      type: Boolean,
    },
    dependentFields: {
      required: true,
      type: Array,
    },
    depth: {
      required: true,
      type: Number,
    },
    isEditable: {
      default: true,
      type: Boolean,
    },
    parentList: {
      required: false,
      type: Array,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      branchList: [],
      form: {
        id: 0,
        field_type_id: 0,
        name: "",
        is_default_field: 0,
        validate_text_admin: null,
        validate_text_customer: null,
        label_admin: "",
        label_customer: "",
        is_require_submit: 0,
        is_require_close: 0,
        is_customer_view: 0,
        is_customer_edit: 0,
        is_customer_require_submit: 0,
        ticket_form_id: 0,
        ticket_field_id: 0,
        sort_order: 0,
        is_required: 0,
        is_dependent: 0,
        deleted: 0,
        field_choice: [],
        field_condition: [],
        field_dependent: [],
        select_branch: [],
        branchList: [],
      },
      isSubmit: false,
    };
  },
  watch: {
    item: {
      handler: function (newValue) {
        this.form = JSON.parse(JSON.stringify(newValue));
        this.setSequence();
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    title() {
      return "Add Dependent Field";
    },
  },
  validations() {
    return {
      form: {
        label_admin: {
          required: requiredIf(function (item) {
            return [13, 14].includes(item.field_type_id) ? false : true;
          }),
        },
        label_customer: {
          required: requiredIf(function (item) {
            return [10, 11, 13, 14].includes(item.field_type_id) ? false : true;
          }),
        },
        name: {
          required,
        },
        field_dependent: {
          $each: {
            ticket_field_id: {
              required,
            },
          },
        },
      },
    };
  },
  created() {},
  methods: {
    setSequence() {
      let fieldDependentByType = 1;

      this.form.field_dependent = this.form.field_dependent.sort(
        (a, b) => a.depen_on_choice_id - b.depen_on_choice_id
      );

      this.form.field_dependent.forEach((element, index) => {
        if (
          this.form.field_dependent[index - 1] &&
          element.depen_on_choice_id !==
            this.form.field_dependent[index - 1].depen_on_choice_id
        ) {
          fieldDependentByType += 1;
        }

        element.seq = fieldDependentByType;
      });

      this.$forceUpdate();
    },
    async addDependentField() {
      const fieldDependentId = await this.form.field_dependent?.map(
        (el) => el.depen_on_choice_id
      );

      this.form.field_choice = this.form.field_choice?.map((el) => {
        return { ...el, disabled: fieldDependentId?.includes(el.id) };
      });

      const fieldChoice = this.form.field_choice?.filter((el) => !el.disabled);

      const dependentChoice = await this.dependentFields.filter(
        (el) => el.id !== this.form.ticket_field_id
      );

      const newField = {
        ticket_form_field_id: 0,
        ticket_field_id: null,
        parent_id: this.form.ticket_field_id,
        depen_on_choice_id: fieldChoice[0].id,
        depth: this.depth + 1,
        root: 0,
        sort_order: this.form.field_dependent?.length
          ? this.form.field_dependent.length - 1
          : 1,
        id: 0,
        dependentChoice,
        isNew: true,
        seq: 0,
      };
      this.form.field_dependent.push(
        JSON.parse(
          JSON.stringify({
            ...newField,
          })
        )
      );
      this.setSequence();
    },
    isDependentField() {
      let fieldDependentByType = 1;
      this.form.field_dependent?.forEach((element, index) => {
        if (
          this.form.field_dependent[index + 1] &&
          element.depen_on_choice_id !==
            this.form.field_dependent[index + 1].depen_on_choice_id
        )
          fieldDependentByType += 1;
      });

      return fieldDependentByType < this.form.field_choice.length;
    },
    getChoice(list, depen_on_choice_id) {
      const parentIds =
        this.depth > 0
          ? this.parentList.map((parent) => parent.ticket_field_id)
          : [];
      return list
        .filter(
          (el) =>
            el.id !== this.form.ticket_field_id && !parentIds.includes(el.id)
        )
        .map((el) => {
          const choiceIds = this.form.field_dependent
            .filter((field) => field.depen_on_choice_id == depen_on_choice_id)
            .map((field) => field.ticket_field_id);

          return {
            ...el,
            $isDisabled: choiceIds.includes(el.id) ? true : false,
          };
        });
    },
    isFirstOfType(depen_on_choice_id, item) {
      const choiceOfType = this.form.field_dependent.filter(
        (el) => el.depen_on_choice_id == depen_on_choice_id
      );
      return choiceOfType[0] == item;
    },
    isLastOfType(depen_on_choice_id, item) {
      const choiceOfType = this.form.field_dependent?.filter(
        (el) => el.depen_on_choice_id == depen_on_choice_id
      );

      return (
        item.dependentChoice &&
        choiceOfType[choiceOfType.length - 1] &&
        choiceOfType[choiceOfType.length - 1] == item &&
        choiceOfType.length <
          this.getChoice(this.dependentFields, depen_on_choice_id).length
      );
    },
    addField(item) {
      const ids = this.form.field_dependent
        .filter(
          (dependent) => dependent.depen_on_choice_id == item.depen_on_choice_id
        )
        .map((el) => el.ticket_field_id);

      const newItem = JSON.parse(JSON.stringify({ ...item }));

      newItem.dependentChoice = newItem.dependentChoice.map((el) => {
        return { ...el, $isDisabled: ids.includes(el.id) };
      });
      this.form.field_dependent.push({
        ...newItem,
        depen_on_choice_id: item.depen_on_choice_id,
        ticket_field_id: null,
      });
      this.setSequence();
    },
    onFieldTypeChange(type, index, id) {
      this.form.field_dependent = this.form.field_dependent.map((el) => {
        if (el.depen_on_choice_id == id) {
          el.depen_on_choice_id = type;
        }

        return { ...el };
      });
    },

    show() {
      this.isShowSidebar = true;
      this.$v.form.$reset();
      this.form = JSON.parse(
        JSON.stringify({
          ...this.item,
        })
      );
      this.setSequence();
    },
    hide() {
      this.isShowSidebar = false;
    },

    submitAction() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.form.field_dependent = this.form.field_dependent.map((el) => {
        return { ...el, isNew: false };
      });
      this.$emit("submit", this.form);
    },
    async getBranchList(field, id) {
      await this.$store.dispatch("getBranch", parseInt(id));
      field.branchList = this.$store.state.coupon.branchList.detail;
    },

    async changeFieldType(value) {
      if (value == 1) {
        this.form.field_condition = [
          { field_condition_type_id: 1, value: "text" },
        ];
        this.form.field_choice = [];
      } else if (value >= 2 && value <= 4) {
        this.form.field_condition = [];
        this.form.field_choice = [
          { name: "choice 1", sort_order: 0, isDefault: 0 },
        ];
      } else if (value == 5 || value == 7) {
        this.form.field_condition = [];
        this.form.field_choice = [{ name: "" }];
      } else if (value == 6) {
        this.form.field_choice = [];
        this.form.field_condition = [];
        this.form.branchList = [];
        const idBranch = this.form.branch_group_id || 0;
        await this.getBranchList(this.form, idBranch);
      } else {
        this.form.field_condition = [];
        this.form.field_choice = [];
      }
    },
    selectBranch(value) {
      this.form.select_branch = value;
      this.$forceUpdate();
    },

    questionTypeText(type) {
      if (type != undefined)
        if (type == 0) {
          return "Customer Detail";
        } else {
          let index = this.fieldTypes.findIndex((field) => field.id == type);
          return this.fieldTypes[index].name;
        }
    },
    labelDescription(item) {
      if (!item) return "";
      return item.field_choice?.map((el) => el.name).join(", ");
    },
    getSelectedField(id) {
      if (!id) return null;
      return this.dependentFields.find((el) => el.id === id);
    },
    getDependentChoice(fieldChoiceId) {
      return this.dependentFields
        .map((el) => {
          return { ...el, $isDisabled: fieldChoiceId?.includes(el.id) };
        })
        .filter((el) => {
          return el.id !== this.form.ticket_field_id;
        });
    },
    async onFieldChange(selected, index) {
      this.form.field_dependent[index] = {
        ...this.form.field_dependent[index],
        ...selected,
        dependentChoice: this.form.field_dependent[index].dependentChoice,
        ticket_field_id: selected.id,
        ticket_form_field_id: 0,
        id: 0,
      };
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: unset;
}
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0.5rem;
}
.text-filter {
  font-weight: 600;
}

.text-filter {
  font-weight: 600;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}

input.search-bar::placeholder {
  text-transform: capitalize;
}
.form-group {
  margin-bottom: 0.5rem !important;
}

.dependent-form {
  background-color: var(--theme-secondary-color);
  padding: 16px;
  height: 100%;

  &.is-first {
    padding-bottom: 0;
    margin-top: 12px;
  }

  &:not(.is-first) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.question-card-title {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 14px;
}

.type-box {
  padding: 2px 8px;
  color: var(--primary-color);
  background-color: var(--theme-secondary-color);
  border-radius: 6px;
}

.text-light-grey {
  color: #9a9a9a;
}

::v-deep .multiselect {
  &.error {
    .multiselect__tags {
      border: 1px solid red;
    }
  }

  .multiselect__tags {
    min-height: 0;
    height: auto;
    padding: 6px 12px;
  }

  .multiselect__single {
    margin: 0;
    padding: 0;
  }

  .multiselect__placeholder {
    margin-bottom: 0;
  }
}
</style>
