<template>
  <div>
    <div class="content-between">
      <div class="label-header">Billing Address</div>
      <div class="text-gray">
        <span v-if="limit != 0"> {{ list.length }}/{{ limit }}</span>
      </div>
    </div>
    <div class="d-flex flex-column row-gap-lg">
      <template v-for="items of list">
        <div
          :key="'Billing-list' + items.id"
          class="box-address"
          @click="getById(items.id)"
        >
          <div class="color-primary">
            {{ items.billing_address_type == 0 ? "บุคคลธรรมดา" : "นิติบุคคล" }}
          </div>
          <div>
            <span class="text-bold text-black">
              <span v-if="items.prefix">{{ items.prefix }}&nbsp;</span
              >{{ items.first_name }}
              {{ items.last_name }}
            </span>
            <span class="text-gray">
              <b-img
                :src="`https://flagcdn.com/48x36/${
                  items.telephone_country_code
                    ? items.telephone_country_code.toLowerCase()
                    : 'th'
                }.png`"
                style="width: 18px; height: 14px; margin-right: 5px"
              ></b-img>
              ({{ items.telephone_country_dial_code }})
              {{ convertTelePhone(items.telephone) }}
            </span>
          </div>
          <p class="text-gray mb-0">
            <span v-html="displayAddress(items)"></span>
            <!-- <template v-if="items.address">{{ items.address }}</template>
            <template v-if="items.building_name">
              {{ items.building_name }}</template
            >
            <template v-if="items.alley"> ซอย{{ items.alley }}</template>
            <template v-if="items.road"> ถนน{{ items.road }}</template>
            <template v-if="items.sub_district">
              แขวง{{ items.sub_district }}</template
            >
            <template v-if="items.district"> เขต{{ items.district }}</template>
            <template v-if="items.province"> {{ items.province }}</template>
            <template v-if="items.zip_code"> {{ items.zip_code }}</template>
            <template v-if="items.country"> {{ items.country }}</template>
            <br /> -->
          </p>
          <div class="box-default" v-if="items.is_default == 1">Default</div>
        </div>
      </template>
      <div
        class="text-center box-address add"
        v-if="limit == 0 || list.length < limit"
      >
        <b-button variant="custom-primary" @click="addAddress">
          <font-awesome-icon icon="plus-square" class="pointer mr-2" />Add
          Address
        </b-button>
      </div>
    </div>
    <Sidebar
      ref="sidebar"
      header="Billing Address"
      @submit="submit"
      @hidden="clearField"
    >
      <div class="my-3">
        <b-row>
          <b-col cols="12" class="content-between">
            <span class="label-header">Billing Address Type</span>
            <span
              class="cursor-pointer text-underline"
              @click="useProfile"
              v-if="!form.id"
            >
              Fill With Profile
            </span>
          </b-col>
          <b-col cols="4">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="billing_address_type"
                v-model="form.billing_address_type"
                :options="[
                  { value: '0', text: 'Personal' },
                  { value: '1', text: 'Company' },
                ]"
                :aria-describedby="ariaDescribedby"
                @change="handleChangeType"
                name="billing_address_type-options"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <div class="break-normal"></div>
          <b-col cols="12" md="6">
            <InputText
              :textFloat="
                form.billing_address_type == 1
                  ? 'Business Reg. No (Tax ID)'
                  : 'Tax ID.'
              "
              :placeholder="
                form.billing_address_type == 1
                  ? 'Business Reg. No (Tax ID)'
                  : 'Tax ID.'
              "
              type="number"
              name="tax-id"
              class="mb-2"
              isRequired
              v-model="form.tax_id"
              :v="$v.form.tax_id"
              :isValidate="$v.form.tax_id.$error"
            />
          </b-col>
          <div class="break-normal"></div>
          <b-col cols="12" md="6">
            <!-- {{ form.prefix }} -->
            <InputSelect
              :title="form.billing_address_type == 1 ? 'Title' : 'Name Title'"
              :name="form.billing_address_type == 1 ? 'Title' : 'Name-Title'"
              :options="[]"
              value=""
              :disabled="true"
              v-if="isPrefixLoading"
            >
              <!-- isRequired -->
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >Loading...
                </b-form-select-option>
              </template>
            </InputSelect>
            <InputSelect
              v-else
              :title="
                form.billing_address_type == 1 ? 'Business Title' : 'Name Title'
              "
              :name="
                form.billing_address_type == 1 ? 'Business Title' : 'Name-Title'
              "
              :options="prefixOptions"
              v-model="form.prefix"
              valueField="name"
              textField="name"
            >
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >Please Select
                </b-form-select-option>
              </template>
            </InputSelect>
          </b-col>
          <b-col cols="12" md="6" v-if="checkOtherValue()">
            <InputText
              :textFloat="
                form.billing_address_type == 1 ? 'Business Title' : 'Name Title'
              "
              :placeholder="
                form.billing_address_type == 1 ? 'Business Title' : 'Name Title'
              "
              type="text"
              name="tax-id"
              class="mb-2"
              v-model="form.prefix_other"
            />
          </b-col>

          <div class="break-normal"></div>
          <b-col cols="12" md="6">
            <InputText
              textFloat=""
              :placeholder="
                form.billing_address_type == 1 ? 'Business Name' : 'Name'
              "
              type="text"
              name="firstname"
              isRequired
              v-model="form.first_name"
              :v="$v.form.first_name"
              customLabel
              :isValidate="$v.form.first_name.$error"
            >
              <template #textFloat>
                <div class="content-between">
                  <div class="main-label">
                    {{
                      form.billing_address_type == 1 ? "Business Name" : "Name"
                    }}
                    <span class="text-danger">*</span>
                  </div>
                  <div
                    class="text-gray f-14"
                    v-if="form.billing_address_type == 1"
                  >
                    ใส่เฉพาะธุรกิจ(บริษัทไทยแลนด์จำกัด = ไทยแลนด์)
                  </div>
                </div>
              </template></InputText
            >
          </b-col>
          <div class="break-normal" v-if="form.billing_address_type == 1"></div>
          <b-col cols="12" md="6" v-if="form.billing_address_type == 1">
            <div>Branch Type</div>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="branch-type"
                v-model="form.branch_type"
                :options="[
                  { value: 'สำนักงานใหญ่', text: 'Head Office' },
                  { value: 'สาขา', text: 'Branch' },
                ]"
                :aria-describedby="ariaDescribedby"
                name="branch-type"
                @change="changeBranchType"
              >
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            v-if="form.billing_address_type == 1 && form.branch_type == 'สาขา'"
          >
            <div class="d-flex" style="column-gap: 15px">
              <InputText
                textFloat="Branch Number"
                placeholder="Branch Number"
                type="text"
                name="Branch Number"
                style="flex: 1"
                isRequired
                v-model="form.company_branch_no"
                :v="$v.form.company_branch_no"
                :isValidate="$v.form.company_branch_no.$error"
              />
              <InputText
                textFloat="Branch Name"
                placeholder="Branch Name"
                type="text"
                name="Branch Name"
                style="flex: 1"
                isRequired
                v-model="form.company_branch"
                :v="$v.form.company_branch"
                :isValidate="$v.form.company_branch.$error"
              />
            </div>
          </b-col>
          <b-col cols="12" md="6" v-if="form.billing_address_type == '0'">
            <InputText
              textFloat="Surname"
              placeholder="Surname"
              type="text"
              name="surname"
              isRequired
              v-model="form.last_name"
              :v="$v.form.last_name"
              :isValidate="$v.form.last_name.$error"
            />
          </b-col>
          <div class="break-normal" v-if="form.billing_address_type == 1"></div>

          <b-col cols="12" md="6">
            <InputText
              textFloat="Email"
              placeholder="Email"
              type="email"
              name="email"
              v-model="form.email"
            />
          </b-col>
          <b-col cols="6">
            <InputTelephoneWithCountry
              textFloat="Telephone"
              placeholder="Telephone"
              :country="form.telephone_country_id"
              @set="(val) => (form.telephone_country_id = val)"
              v-model="form.telephone"
              isRequired
              :v="$v.form.telephone"
              :isValidate="$v.form.telephone.$error"
            />
          </b-col>

          <b-col cols="12" md="6">
            <InputSelect
              title="Country"
              name="Name-Title"
              :options="Country"
              v-model="form.country"
              valueField="englishName"
              textField="englishName"
              isRequired
              :v="$v.form.country"
              :isValidate="$v.form.country.$error"
              @onDataChange="changeCountry"
            >
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >Please Select
                </b-form-select-option>
              </template>
            </InputSelect>
          </b-col>
          <div class="break-normal"></div>
          <!-- <template v-if="form.country == 'ประเทศไทย' || form.country == ''"> -->
          <b-col cols="12" md="6">
            <InputText
              textFloat="Address"
              placeholder="Address"
              type="text"
              isRequired
              name="address"
              v-model="form.address"
              :v="$v.form.address"
              :isValidate="$v.form.address.$error"
            />
          </b-col>
          <b-col cols="12" md="6">
            <InputText
              textFloat="Building / Village Name"
              placeholder="Building / Village Name"
              type="text"
              name="building"
              v-model="form.building_name"
            />
          </b-col>
          <b-col cols="12" md="6">
            <InputText
              textFloat="Alley / Soi"
              placeholder="Alley / Soi"
              type="text"
              name="alley"
              v-model="form.alley"
            />
          </b-col>

          <b-col cols="12" md="6">
            <InputText
              textFloat="Road"
              placeholder="Road"
              type="text"
              name="road"
              v-model="form.road"
            />
          </b-col>
          <b-col cols="12" md="6">
            <template v-if="form.country == 'ประเทศไทย' || form.country == ''">
              <InputSelect
                title="Province"
                name="Province"
                :options="addressOptions.province.field_choices"
                v-model="form.province"
                valueField="name"
                textField="name"
                isRequired
                :v="$v.form.province"
                :isValidate="$v.form.province.$error"
                @onDataChange="
                  handleChange(
                    $event,
                    addressOptions.province.field_choices,
                    'district',
                    'sub_district'
                  )
                "
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >Please Select
                  </b-form-select-option>
                </template>
              </InputSelect>
            </template>
            <template v-else>
              <InputText
                textFloat="Province"
                placeholder="Province"
                type="text"
                name="province"
                v-model="form.province"
                :v="$v.form.province"
                :isValidate="$v.form.province.$error"
                :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 50);'"
              />
            </template>
          </b-col>
          <b-col cols="12" md="6">
            <template v-if="form.country == 'ประเทศไทย'">
              <InputSelect
                title="District"
                name="District"
                :options="[]"
                value=""
                :disabled="true"
                v-if="loading.district"
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >Loading...
                  </b-form-select-option>
                </template>
              </InputSelect>
              <InputSelect
                v-else
                title="District"
                name="District"
                :options="options.district.field_choices || []"
                v-model="form.district"
                valueField="name"
                textField="name"
                isRequired
                :v="$v.form.district"
                :isValidate="$v.form.district.$error"
                :disabled="!form.province"
                @onDataChange="
                  handleChange(
                    $event,
                    options.district.field_choices,
                    'subdistrict',
                    'sub_district'
                  )
                "
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >Please Select
                  </b-form-select-option>
                </template>
              </InputSelect></template
            >
            <template v-else>
              <InputText
                textFloat="District"
                placeholder="District"
                type="text"
                name="District"
                v-model="form.district"
                :v="$v.form.district"
                :isValidate="$v.form.district.$error"
                :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 50);'"
              />
            </template>
          </b-col>
          <b-col cols="12" md="6">
            <template v-if="form.country == 'ประเทศไทย'">
              <InputSelect
                title="Subdistrict"
                name="Subdistrict"
                :options="[]"
                value=""
                :disabled="true"
                v-if="loading.subdistrict"
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >Loading...
                  </b-form-select-option>
                </template>
              </InputSelect>
              <InputSelect
                v-else
                title="Subdistrict"
                name="subdistrict"
                :options="options.subdistrict.field_choices || []"
                v-model="form.sub_district"
                :v="$v.form.sub_district"
                :isValidate="$v.form.sub_district.$error"
                valueField="name"
                textField="name"
                isRequired
                @onDataChange="
                  handleChange(
                    $event,
                    options.subdistrict.field_choices,
                    'zip_code'
                  )
                "
                :disabled="!form.district"
              >
                <!-- isRequired -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled
                    >Please Select
                  </b-form-select-option>
                </template>
              </InputSelect></template
            >
            <template v-else>
              <InputText
                textFloat="Subdistrict"
                placeholder="Subdistrict"
                type="text"
                name="Subdistrict"
                v-model="form.sub_district"
                :v="$v.form.sub_district"
                :isValidate="$v.form.sub_district.$error"
                :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 50);'"
              />
            </template>
          </b-col>

          <b-col cols="12" md="6">
            <InputText
              textFloat="Zip Code"
              placeholder="Zip Code"
              type="number"
              name="zip_code"
              v-model="form.zip_code"
              :v="$v.form.zip_code"
              :isValidate="$v.form.zip_code.$error"
              :isRequired="form.country == 'ประเทศไทย'"
              :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 5);'"
            />
          </b-col>
          <!-- </template> -->

          <b-col cols="12">
            <div class="content-between">
              <b-form-checkbox
                id="is_default"
                name="is_default"
                :value="1"
                :unchecked-value="0"
                v-model="form.is_default"
              >
                Default
              </b-form-checkbox>
              <b-button
                variant="custom-link"
                @click="deleteAddress"
                v-if="form.id"
                >Delete</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </Sidebar>
  </div>
</template>

<script>
import Sidebar from "./Sidebar";
import Country from "@/assets/lang/country.json";

import {
  required,
  minLength,
  maxLength,
  requiredIf,
  email,
} from "vuelidate/lib/validators";
const telephoneFormat = (val) => {
  let validate = new RegExp(/^\d+(\-\d{1,20})?$/);

  if (validate.test(val)) return true;
  return false;
};
const TelephoneMinLength = (val, e, a) => {
  if (!val) return true;
  if (val.split("-")[0].length < 9 && e.telephone_country_id == 1) return false;
  return true;
  // return minLength(val);
};
export default {
  components: { Sidebar },
  props: {
    addressOptions: {
      required: true,
      type: Object,
    },
    limit: { required: true, default: 0, type: Number | String },
    getField: {
      required: false,
    },
    formMain: {
      required: false,
    },
  },
  validations() {
    return {
      form: {
        tax_id: {
          required,
          minLength: minLength(13),
          maxLength: maxLength(13),
        },
        first_name: { required },
        last_name: {
          required: requiredIf(() => {
            return this.form.billing_address_type == 0;
          }),
        },

        telephone: {
          required,
          telephoneFormat,
          TelephoneMinLength,
          // // minLength: minLength(9),
          // maxLength: maxLength(10),
        },
        country: { required },
        address: {
          required,
        },
        company_branch: {
          required: requiredIf(() => {
            return (
              this.form.branch_type == "สาขา" &&
              this.form.billing_address_type == 1
            );
          }),
        },
        company_branch_no: {
          required: requiredIf(() => {
            return (
              this.form.branch_type == "สาขา" &&
              this.form.billing_address_type == 1
            );
          }),
        },
        province: {
          required: requiredIf(() => {
            return this.form.country == "ประเทศไทย";
          }),
        },
        district: {
          required: requiredIf(() => {
            return this.form.country == "ประเทศไทย";
          }),
        },
        sub_district: {
          required: requiredIf(() => {
            return this.form.country == "ประเทศไทย";
          }),
        },
        zip_code: {
          required: requiredIf(() => {
            return this.form.country == "ประเทศไทย";
          }),
        },
      },
    };
  },
  data() {
    return {
      form: {
        user_guid: "",
        billing_address_type: 0,
        tax_id: "",
        prefix: "",
        first_name: "",
        last_name: "",
        email: "",
        telephone: "",
        telephone_country_id: 1,
        country: "ประเทศไทย",
        address: "",
        branch_type: "สำนักงานใหญ่",
        building_name: "",
        alley: "",
        road: "",
        sub_district: "",
        district: "",
        province: "",
        zip_code: "",
        is_default: 0,
      },
      list: [],

      optionalTelephone: [],
      options: { ...this.addressOptions },

      loading: {
        district: false,
        subdistrict: false,
      },
      FieldList: [],
      companyFields: [],
      isPrefixLoading: false,
      Country: Country,
    };
  },
  watch: {
    addressOptions(val) {
      console.log(val);
      this.options = { ...val };
    },
  },
  created() {
    this.getList();
  },
  computed: {
    prefixOptions() {
      let field = this.FieldList.find((el) => el.field_profile_type_id == 2);
      return field ? field.field_choices : [];
    },
  },
  methods: {
    async getById(id) {
      this.$bus.$emit("showLoading");
      // await this.$emit("getField");
      this.getField().then(async () => {
        const result = await this.axios(
          `/customer/GetBillingInformationById/${id}`
        );
        await this.getFields(result.data.detail.data.billing_address_type);
        let data = JSON.parse(JSON.stringify(result.data.detail.data));
        //
        await this.$nextTick();
        this.form = result.data.detail.data;
        if (
          !this.prefixOptions.find((el) => el.name == this.form.prefix) &&
          this.form.prefix
        ) {
          this.form.prefix_other = data.prefix;
          this.form.prefix = this.prefixOptions.find(
            (el) => el.choice_type_id == 3
          ).name;
        }

        if (data.country == "ประเทศไทย") {
          if (data.province) {
            await this.handleChange(
              data.province,
              this.addressOptions.province.field_choices,
              "district",
              "subdistrict",
              false
            );
          }
          if (data.district) {
            await this.handleChange(
              data.district,
              this.options.district.field_choices,
              "subdistrict",
              "sub_district",
              false
            );
          }
        }
        this.$refs.sidebar.show();
        this.$bus.$emit("hideLoading");
      });
    },
    editAddress(id) {
      this.getById(id);
    },
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.$bus.$emit("showLoading");
      this.form.user_guid = this.$route.params.id;
      if (this.optionalTelephone)
        this.form.optional_telephone = this.optionalTelephone.join(",");
      let payload = { ...this.form };
      // if (payload.branch_type == "สาขา") {
      //   payload.branch_type = `${this.form.company_branch}*,${this.form.company_branch_no}`;
      // }
      if (this.checkOtherValue()) payload.prefix = this.form.prefix_other;
      var result = null;
      if (this.form.id) {
        result = await this.axios.put(
          `/customer/UpdateBillingInformation`,
          payload
        );
      } else {
        result = await this.axios.post(
          `/customer/CreateBillingInformation`,
          payload
        );
      }
      this.$bus.$emit("hideLoading");
      if (result.data.result) {
        this.getList();
        this.successAlert();
        this.$refs.sidebar.hide();
      } else this.errorAlert(result.data.message);
    },
    async addAddress() {
      this.$bus.$emit("showLoading");
      this.getField().then(async () => await this.getFields());
      this.$refs.sidebar.show();
      this.$bus.$emit("hideLoading");
    },

    async handleChange(val, field, $set, $set2, clear = true) {
      let payload = field.find((el) => el.name == val);
      if (!payload) return;
      if ($set == "zip_code") {
        this.form[$set] = payload ? payload.zip_code : "";
      } else {
        this.loading[$set] = true;
        const result = await this.axios(
          `/setting/Getsubaddress/${payload.id}/${$set == "district" ? 1 : 2}`
        );

        this.$nextTick(() => {
          this.options[$set].field_choices = result.data.detail[$set];

          if (clear) {
            this.form[$set] = "";
            this.form.zip_code = "";
            if ($set2) this.form[$set2] = "";
          }
          this.loading[$set] = false;
        });
      }
    },
    async getList() {
      const result = await this.axios(
        `/customer/GetBillingInformationList/${this.$route.params.id}`
      );

      this.list = result.data.detail.result;
    },
    async deleteAddress() {
      try {
        this.$bus.$emit("showLoading");
        const result = await this.axios.delete(
          `/customer/DeleteBillingInformation/${this.form.id}`
        );
        this.$bus.$emit("hideLoading");
        if (result.data.result) {
          this.successAlert();
          this.$refs.sidebar.hide();
          this.getList();
          // this.back();
        } else {
          this.errorAlert(result.data.message);
        }
      } catch (error) {
        this.$bus.$emit("hideLoading");
        this.errorAlert(error.message);
      }
    },
    async getFields(type) {
      this.isPrefixLoading = true;
      // await this.$emit("getField");
      if (type == 1) {
        if (this.companyFields.length > 0) {
          this.isPrefixLoading = false;
          return (this.FieldList = this.companyFields);
        }
        const { data } = await this.axios(`/setting/getcompanyform`);
        this.companyFields = data.detail.profileField;
        this.FieldList = data.detail.profileField;
      } else {
        if (this.FieldList.length == 0)
          await this.$store.dispatch("getFieldProfiletypes");

        let data = this.$store.state.form.profileLists;

        this.FieldList = data.detail.fields;
      }
      this.isPrefixLoading = false;
      return true;
    },
    validateOptional(val) {
      if (val.length == 10) {
        return val;
      }
      return;
    },
    handleChangeType(val) {
      this.form.prefix = "";
      this.form.last_name = "";
      this.getFields(val);
    },
    clearField() {
      this.form = {
        user_guid: "",
        billing_address_type: 0,
        telephone_country_id: 1,
        branch_type: "สำนักงานใหญ่",
        tax_id: "",
        prefix: "",
        first_name: "",
        last_name: "",
        email: "",
        telephone: "",
        country: "ประเทศไทย",
        address: "",
        building_name: "",
        alley: "",
        road: "",
        sub_district: "",
        district: "",
        province: "",
        zip_code: "",
        is_default: 0,
      };
      this.$v.form.$reset();
    },
    checkOtherValue() {
      let check = this.prefixOptions.find((el) => el.name == this.form.prefix);

      return check && check.choice_type_id == 3;
    },
    changeCountry() {
      this.form = {
        ...this.form,
        address: "",
        building_name: "",
        alley: "",
        road: "",
        district: "",
        sub_district: "",
        province: "",
        zip_code: "",
      };
    },
    changeBranchType(val) {
      if (val == "สำนักงานใหญ่") {
        this.form.company_branch_no = "";
        this.form.company_branch = "";
      }
    },
    async useProfile() {
      this.$bus.$emit("showLoading");
      await this.handleChangeType(this.formMain.is_company);
      this.form = {
        ...this.form,
        ...this.formMain,
        province: this.formMain.province || "",
        district: this.formMain.district || "",
        address: this.formMain.home_address || "",
        building_name: this.formMain.town || "",
        sub_district: this.formMain.subdistrict || "",
        first_name: this.formMain.first_name_th,
        last_name: this.formMain.last_name_th,
        billing_address_type: this.formMain.is_company,
        branch_type: this.formMain.company_branch_type,
      };

      if (this.form.country == "ประเทศไทย") {
        if (this.form.province) {
          await this.handleChange(
            this.form.province,
            this.addressOptions.province.field_choices,
            "district",
            "subdistrict",
            false
          );
        }
        if (this.form.district) {
          await this.handleChange(
            this.form.district,
            this.options.district.field_choices,
            "subdistrict",
            "sub_district",
            false
          );
        }
      }
      this.$bus.$emit("hideLoading");
      this.$nextTick();
    },
  },
};
</script>

<style lang="scss" scoped>
.box-address {
  border-width: 1px;
  border-radius: 0.5rem;
  border-color: rgba(0, 0, 21, 0.2);
  border-style: solid;
  padding: 1rem;
  position: relative;
  cursor: pointer;
  &.add {
    border-style: dashed;
    border-color: var(--primary-color);
  }
  .box-default {
    color: green;
    border: 1px solid green;
    border-radius: 0.2rem;
    position: absolute;
    top: 10%;
    right: 10px;
    font-size: 12px;
    padding: 0 5px;
  }
}
</style>
