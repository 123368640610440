<template>
  <div>
    <b-row class="no-gutters">
      <b-col>
        <h1 class="mr-sm-4 header-tablepage mb-3">USER MANUAL</h1>
      </b-col>
    </b-row>
    <div class="bg-white p-3">
      <InputText placeholder="Search Manual Guide" v-model="search" />
      <b-row class="align-items-center row-gap-2xl">
        <template v-for="item of filterItems">
          <b-col :key="'name' + item.id" cols="6" class="d-flex">
            <span
              class="icon-guide cursor-pointer"
              @click="openLink(item.tutorial)"
            >
              <font-awesome-icon icon="book"></font-awesome-icon>
            </span>
            <div class="d-inline-flex flex-wrap">
              <span class="name-manual">
                {{ item.name }}
              </span>

              <span
                @click="openLink(item.tutorial)"
                v-if="item.tutorial"
                class="cursor-pointer text-underline color-primary mr-2"
                >Manual</span
              >
              <span
                @click="openLink(item.video)"
                v-if="item.video"
                class="cursor-pointer text-underline color-primary"
                >Video</span
              >
            </div>
          </b-col>
        </template>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      items: [],
    };
  },
  watch: {},
  computed: {
    filterItems() {
      return this.search
        ? this.items.filter((el) =>
            el.name.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.items;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await this.axios("setting/GetUserManual");
      this.items = res.data.detail;
    },
    openLink(tutorial) {
      window.open(tutorial, "_blank");
    },
  },
};
</script>

<style>
.icon-guide {
  background-color: var(--theme-secondary-color);
  width: 50px;
  height: 50px;
  border-radius: 10px;
  color: var(--primary-color);
  padding: 10px;
  text-align: center;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}
.name-manual {
  flex-basis: 100%;
  flex-grow: 1;
  width: 100%;

  flex-shrink: 0;
}
</style>
