<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">Role detail</h1>
        </div>
        <div class="title-tabs mt-3">Role Info</div>
        <b-container class="no-gutters bg-white">
          <div class="pt-3">
            <b-row>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="Role Name"
                  placeholder="Role Name"
                  type="text"
                  name="role_name"
                  isRequired
                  :isValidate="$v.form.name.$error"
                  :v="$v.form.name"
                  v-model="form.name"
                />
              </b-col>
            </b-row>
          </div>
        </b-container>
        <div class="title-tabs mt-3">Role Condition</div>
        <b-container class="no-gutters bg-white p-3">
          <div class="role-condition">
            <table class="table-row-condition">
              <thead>
                <th width="80%">Detail</th>
                <th width="20%">Accessibility</th>
              </thead>
              <tbody>
                <template v-for="(items, index) of roleCondition">
                  <tr class="border-top" :key="items.id">
                    <td @click="collapseRow('choice-' + index)">
                      <label :for="'checkbox-role-condition-' + items.id">
                        {{ index + 1 }}. {{ items.name }}
                      </label>
                    </td>
                    <td class="text-center">
                      <b-form-checkbox
                        :id="'checkbox-role-condition-' + items.id"
                        :name="'checkbox-role-condition-' + items.id"
                        v-model="items.isCheck"
                        :value="1"
                        :unchecked-value="0"
                        @change="toggleAll(items, $event)"
                      >
                      </b-form-checkbox>
                    </td>
                  </tr>
                  <tr
                    v-for="(item, subIndex) of items.subCondition"
                    :key="'row-' + items.id + subIndex"
                    :class="['choice-' + index, 'row-col']"
                  >
                    <td class="pt-0">
                      <div class="sub-role-conditions">
                        <label :for="'checkbox-sub-role-condition-' + item.id">
                          {{ index + 1 }}.{{ subIndex + 1 }}
                          {{ item.name }}</label
                        >
                      </div>
                    </td>
                    <td class="text-center pt-0">
                      <b-form-checkbox
                        :id="'checkbox-sub-role-condition-' + item.id"
                        :name="'checkbox-sub-role-condition-' + item.id"
                        v-model="item.isCheck"
                        :value="1"
                        :unchecked-value="0"
                        @change="toggleRole(items, items.id, item.id, $event)"
                      >
                      </b-form-checkbox>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </b-container>
        <div class="no-gutters bg-white mt-3 py-2 px-3">
          <b-form-checkbox
            switch
            v-model="form.status"
            class="radio-active"
            value="1"
            unchecked-value="0"
            size="lg"
          >
            <span class="ml-2 main-label">{{
              form.status ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>
      </b-form>
      <FooterAction routePath="/role-management" @submit="saveForm()" />
    </div>

    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalSuccess
      ref="modalAlert"
      msg="บันทึกข้อมูลสำเร็จ"
      @close="closeModalAlert"
    />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import OtherLoading from "@/components/loading/OtherLoading";

import ModalLoading from "@/components/modal/ModalLoading";
import ModalSuccess from "@/components/ModalSuccess";
export default {
  components: {
    InputText,
    InputSelect,
    OtherLoading,
    ModalLoading,
    ModalSuccess,
  },
  validations: {
    form: {
      name: { required },
    },
  },
  computed: {
    mode() {
      return this.$route.params.id != 0;
    },
  },
  watch: {},
  data() {
    return {
      roleCondition: [],
      isLoading: false,
      form: {
        name: "",
        status: 1,
        roleSelect: [],
      },
      isDisable: true,
      id: this.$route.params.id,
      roleDelete: [],
      FreezeRoleSelect: [],
    };
  },
  created() {
    if (this.mode) {
      this.getRoleID();
    } else {
      this.getRoleCondition();
    }
  },
  methods: {
    closeModalAlert() {
      this.$refs.modalAlert.hide();
    },
    async getRoleID() {
      const { data } = await this.axios(`/Role/${this.$route.params.id}`);
      var roles = [];
      this.form = data.detail;
      if (data.detail.roleSelect) {
        this.roleCondition = data.detail.roleSelect.filter((el) => {
          el.subCondition = el.subCondition.filter((el) => el.display);
          return el.display;
        });
        for (const role of data.detail.roleSelect) {
          if (role.isCheck) {
            roles.push(role.id);
          }
          for (const roleSub of role.subCondition) {
            if (roleSub.isCheck) {
              roles.push(roleSub.id);
            }
          }
        }
        roles = roles ? roles : [];
        this.FreezeRoleSelect = roles ? [...roles] : [];
        this.form.roleSelect = roles;
      } else {
        this.getRoleCondition();
        this.form.roleSelect = [];
      }
      // this.form = data.detail;
    },
    async getRoleCondition() {
      const { data } = await this.axios(`/Role/getCondition`);

      this.roleCondition = await data.detail.filter((el) => {
        el.subCondition = el.subCondition.filter((el) => el.display);
        return el.display;
      });
    },
    async saveForm() {
      this.form.status = this.form.status ? 1 : 0;
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        return;
      }

      this.$refs.modalLoading.show();
      if (this.mode) {
        this.form.roleDelete = this.roleDelete;
        this.form.roleSelect = this.form.roleSelect.filter(
          (el) => !this.FreezeRoleSelect.includes(el)
        );
        this.form.id = this.$route.params.id;
        this.axios
          .put(`/Role`, this.form)
          .then((res) => {
            this.$refs.modalLoading.hide();
            if (res.data.result) {
              this.FreezeRoleSelect = this.FreezeRoleSelect.filter(
                (el) => !this.roleDelete.includes(el)
              );
              this.FreezeRoleSelect = [
                ...this.FreezeRoleSelect,
                ...this.form.roleSelect,
              ];
              this.successAlert().then(
                () => (window.location.href = "/role-management")
              );
            } else {
              this.errorAlert(res.data.message);
            }
          })
          .catch((err) => {
            this.errorAlert(
              "เกิดข้อผิดพลาดไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง",
              "เกิดข้อผิดพลาด"
            );
          });
      } else {
        this.axios
          .post(`/Role`, this.form)
          .then((res) => {
            this.$refs.modalLoading.hide();
            if (res.data.result) {
              this.successAlert().then(() =>
                this.$router.push("/role-management")
              );
            } else {
              this.errorAlert(res.data.message);
            }
          })
          .catch((err) => {
            this.errorAlert(
              "เกิดข้อผิดพลาดไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง",
              "เกิดข้อผิดพลาด"
            );
          });
      }
    },

    toggleAll(obj, val) {
      obj.subCondition.map((el) => (el.isCheck = val));
      var subId = obj.subCondition.map((el) => el.id);
      val = val == 1 ? true : false;
      if (val) {
        this.form.roleSelect.push(...subId, obj.id);
        for (const role of this.form.roleSelect) {
          if (this.roleDelete.includes(role)) {
            this.roleDelete.splice(this.roleDelete.indexOf(role), 1);
          }
        }
      } else {
        for (const subCheck of subId) {
          this.form.roleSelect.splice(
            this.form.roleSelect.indexOf(subCheck),
            1
          );

          if (
            this.FreezeRoleSelect.includes(subCheck) &&
            !this.roleDelete.includes(subCheck)
          ) {
            this.roleDelete.push(subCheck);
          }
        }
        if (
          this.FreezeRoleSelect.includes(obj.id) &&
          !this.roleDelete.includes(obj.id)
        ) {
          this.roleDelete.push(obj.id);
        }
        this.form.roleSelect.splice(this.form.roleSelect.indexOf(obj.id), 1);
      }
    },
    toggleRole(obj, id, subId, val) {
      var findCheck = obj.subCondition.filter((el) => el.isCheck);

      val = val == 1 ? true : false;

      if (val) {
        obj.isCheck = 1;
        if (this.form.roleSelect.includes(id)) this.form.roleSelect.push(subId);
        else this.form.roleSelect.push(id, subId);
        if (this.roleDelete.includes(id) || this.roleDelete.includes(subId)) {
          this.roleDelete.splice(this.roleDelete.indexOf(id), 1);
          this.roleDelete.splice(this.roleDelete.indexOf(subId), 1);
        }
      } else if (!val && findCheck.length > 0) {
        this.form.roleSelect.splice(this.form.roleSelect.indexOf(subId), 1);
        if (
          this.FreezeRoleSelect.includes(subId) &&
          !this.roleDelete.includes(subId)
        ) {
          this.roleDelete.push(subId);
        }
      } else {
        obj.isCheck = 0;
        this.form.roleSelect.splice(this.form.roleSelect.indexOf(id), 1);
        this.form.roleSelect.splice(this.form.roleSelect.indexOf(subId), 1);
        if (
          this.FreezeRoleSelect.includes(subId) &&
          !this.roleDelete.includes(subId)
        ) {
          this.roleDelete.push(subId);
          this.roleDelete.push(id);
        }
      }
    },
    collapseRow(id) {
      var pc1 = document.getElementsByClassName(id);
      for (const el of pc1) {
        el.classList.toggle("hide");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-row-condition {
  th {
    text-align: center;
    padding: 0.5rem;
  }
  td {
    border-right: 1px solid #d8dbe0;
    padding: 0.25rem 1rem;
  }
  tr.border-top {
    border-top: 1px solid #d8dbe0;
  }
  label {
    margin-bottom: unset;
  }
  width: 100%;
  border: 1px solid #d8dbe0;
}

::v-deep .custom-select {
  color: var(--font-main-color);
  border: 1px solid #bcbcbc;
  border-radius: 0px;
  padding: 5px 10px;
  height: 31px;
}

.img-box-preview {
  width: 100%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
.reset-password {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  font-size: 12px;
}
.role-condition-row {
  div.row div.text-center {
    border-left: 1px solid black;
  }
  padding: 10px 0 10px 0;
}
.role-condition .role-condition-row:not(:last-child) {
  border-bottom: 1px solid black;
}
.sub-role-conditions {
  padding-inline-start: 40px;
}
.hide {
  display: none;
}
.row-col {
  translate: display 1s all;
}
</style>
