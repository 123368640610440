<template>
  <b-modal
    v-model="showModal"
    ref="modalTestSend"
    @hidden="hide"
    centered
    hide-header
  >
    <div>
      <div class="text-center title">
        กรุณากรอก{{
          type === 2 || type === 4 || type == 3 ? "เบอร์โทรศัพท์" : "อีเมล"
        }}สำหรับส่งทดสอบ
      </div>
      <div v-if="type === 2 || type === 4 || type == 3" class="mt-3">
        <InputText
          textFloat=""
          placeholder="เบอร์โทรศัพท์"
          type="number"
          name="sms"
          :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 10);'"
          v-model="sms"
          :isValidate="$v.sms.$error"
          :v="$v.sms"
        />
      </div>
      <div v-if="type === 1">
        <InputText
          textFloat=""
          placeholder="อีเมล"
          type="text"
          name="email"
          v-model="email"
          :isValidate="$v.email.$error"
          :v="$v.email"
        />
      </div>
    </div>
    <template #modal-footer>
      <b-row>
        <b-col
          ><b-button @click="hide" class="btn-cancel"> ยกเลิก </b-button></b-col
        >
        <b-col
          ><b-button class="btn-confirm" @click.prevent="sendSMS(type)">
            ตกลง
          </b-button></b-col
        >
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { required, email } from "vuelidate/lib/validators";
export default {
  components: {
    InputText,
  },
  data() {
    return {
      sms: "",
      email: "",
      showModal: false,
      type: 0,
    };
  },
  validations: {
    sms: { required },
    email: { required, email },
  },
  methods: {
    show(val) {
      this.type = val;
      this.sms = "";
      this.email = "";
      this.showModal = true;
      this.$v.$reset();
    },
    hide() {
      this.showModal = false;
    },
    sendSMS(typeSend) {
      if (typeSend === 2 || typeSend === 4 || typeSend === 3) {
        this.$v.sms.$touch();
        if (this.$v.sms.$error) {
          return;
        }
        this.$emit("testSendEmailSMS", { data: this.sms, type: typeSend });
        this.hide();
      } else if (typeSend === 1) {
        this.$v.email.$touch();
        if (this.$v.email.$error) {
          return;
        }
        this.$emit("testSendEmailSMS", { data: this.email, type: typeSend });
        this.hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 16px;
}
.btn-test {
  color: #fff;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  border-radius: 5px;
}
::v-deep .modal-footer {
  display: block;
}
.btn-cancel {
  background-color: transparent;
  width: 100%;
}
.btn-confirm {
  background-color: var(--primary-color);
  color: #fff;
  width: 100%;
}
</style>
