<template>
  <div>
    <SideBarFilter
      id="prospectFilter"
      ref="prospectFilter"
      :filter="filters"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <div class="mt-3">
          <b-input-group>
            <b-form-input
              type="text"
              id="header-search-bar"
              class="search-bar"
              @keyup.enter="handleSearch"
              placeholder="Search Customer name, Telephone, Invoice No. "
              v-model="filters.Search"
            >
            </b-form-input>
            <b-input-group-append is-text class="mr-2">
              <b-iconstack font-scale="2" type="submit" @click="handleSearch">
                <b-icon stacked icon="search" scale="0.5" variant="grey">
                </b-icon>
              </b-iconstack>
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="mt-3">
          <div>
            <div class="main-label">Register Date From</div>
            <div :class="['input-container']">
              <datetime
                format="dd/MM/yyyy"
                v-model="filters.RegisterDateFrom"
                value-zone="local"
                ref="startDate"
                placeholder="Please Select Date"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.startDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mt-3 mb-3">
            <div class="main-label">Register Date To</div>
            <div :class="['input-container']">
              <datetime
                format="dd/MM/yyyy"
                v-model="filters.RegisterDateTo"
                :min-datetime="filters.RegisterDateFrom"
                placeholder="Please Select Date"
                value-zone="local"
                ref="endDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.endDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <check-box-anonymous
            :value="filters.allow_anonymous"
            @change="(val) => (filters.allow_anonymous = val)"
            class="mb-3"
          />
        </div>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
export default {
  emits: ["filterApplied"],
  data() {
    return {
      filters: {
        RegisterDateFrom: this.$store.state.filter.from,
        RegisterDateTo: this.$store.state.filter.to,
        Search: "",
        PageNumber: 1,
        RecordPerPage: 15,
        SortColumnId: 0,
        take: 10,
        page: 1,
        sortBy: "",
        sortDesc: "",
        SortType: false,
        allow_anonymous: this.$store.state.filter.allow_anonymous,
      },
      isBusy: false,
    };
  },
  methods: {
    clearFilter() {
      this.filters = {
        RegisterDateFrom: this.$store.state.filter.from,
        RegisterDateTo: this.$store.state.filter.to,
        Search: "",
        PageNumber: 1,
        RecordPerPage: 15,
        SortColumnId: 0,
        take: 10,
        page: 1,
        sortBy: "",
        sortDesc: "",
        SortType: false, // true = ASC, flase = DESC
        allow_anonymous: this.$store.state.filter.allow_anonymous,
      };
      this.$refs.prospectFilter.hide();
      this.$emit("filterApplied", this.filters);
    },
    filterActions() {
      this.$emit("filterApplied", this.filters);
    },
    handleSearch() {
      this.$emit("filterApplied", this.filters);
    },
    showFilter() {
      this.$refs.prospectFilter.show();
    },
  },
};
</script>

<style></style>
