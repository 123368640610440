<template>
  <div>
    <div class="div-input">
      <div :class="['input-custom', { error: isValidate }]">
        <label v-if="textFloat">
          {{ textFloat }}
          <span v-if="isRequired" class="text-danger">*</span>
        </label>
        <div class="d-flex justify-content-center align-items-center">
          <div class="w-100 display-only" v-if="showFileName">
            <input
              ref="file"
              :type="type"
              :placeholder="placeholder"
              :name="name"
              :required="required"
              :value="fileNameDisplay"
              :size="size"
              disabled
            />
            <font-awesome-icon
              icon="times-circle"
              class="text-secondary delete-icon pointer"
              v-if="!noDelete && fileNameDisplay && !disabled"
              @click="deleteImage"
            />
          </div>
          <label class="mb-0 btn-main" :size="size" v-if="!disabled">
            <input
              :multiple="isMultiple"
              type="file"
              v-on:change="handleFileChange"
              :required="required"
              :name="name"
              ref="input"
              :id="id"
              :accept="
                format === 'image'
                  ? type.image
                  : format === 'font'
                  ? '.ttf, .otf,.woff'
                  : format == 'excel'
                  ? type.excel
                  : ''
              "
            />
            <font-awesome-icon
              icon="file-upload"
              color="white"
              class="bg-icon mr-2"
              :size="size"
            />Choose file
          </label>
        </div>
      </div>
      <p class="detail-format">{{ text }}</p>
      <p class="detail-format text-danger" v-if="showWarningMessage">
        {{ warningMessage }}
      </p>
      <div v-if="v && v.$error" class="mt-1">
        <span class="text-error" v-if="v.required == false"
          >This field can’t be empty</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    textFloat: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
    format: {
      required: true,
      type: String,
    },
    fileName: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    name: {
      required: false,
      type: String,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    placeholder: {
      required: true,
      type: String,
    },
    size: {
      required: false,
      type: String,
    },
    downloadPath: {
      required: false,
      type: String,
    },
    v: {
      required: false,
      type: Object,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    id: {
      required: false,
      type: String,
    },
    showFileName: {
      required: false,
      type: Boolean,
      default: true,
    },
    data: {
      required: false,
      default: () => {
        return { condition_type_id: 0 };
      },
    },
    indexCondition: {
      required: false,
    },
    indexGroup: {
      required: false,
    },
    isValidType: {
      required: false,
      default: true,
    },
    disabled: {
      required: false,
      default: false,
    },
    noDelete: {
      required: false,
      default: false,
    },
    showWarningMessage: {
      required: false,
      default: false,
    },
    warningMessage: {
      required: false,
      default: "",
    },
  },
  data() {
    return {
      file: "",
      value: "",
      type: {
        all: ["image/jpeg", "image/png", "video/mp4"],
        file: ["image/jpeg", "image/png", "application/pdf"],
        pdf: ["application/pdf"],
        png: ["image/png"],
        image: ["image/jpeg", "image/png"],
        video: ["video/mp4"],
        excel: [
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ],
      },
      error: "",
      hasError: false,
      hasImage: false,
      imgModal: null,
      msgModal: null,
      isSuccess: false,
    };
  },
  computed: {
    fileNameDisplay: function () {
      if (this.fileName != null) {
        return this.fileName.split("//").pop().split("/")[2] || this.fileName;
      } else {
        return "";
      }
    },
  },
  methods: {
    handleFileChange(e) {
      this.hasError = false;
      this.error = "";

      var _validFileExtensions = this.type.file;
      if (this.format == "image") {
        _validFileExtensions = this.type.image;
      } else if (this.format == "video") {
        _validFileExtensions = this.type.video;
      } else if (this.format == "all") {
        _validFileExtensions = this.type.all;
      } else if (this.format == "pdf") {
        _validFileExtensions = this.type.pdf;
      } else if (this.format == "excel") {
        _validFileExtensions = this.type.excel;
      }
      this.value = e.target.files[0];

      if (this.value.name.includes(".csv")) {
        alert("This file type is not supported");
        return;
      }

      if (e.target.files.length) {
        if (
          this.isValidType &&
          _validFileExtensions.indexOf(this.value.type) < 0 &&
          this.format
        ) {
          this.errorAlert("This file type is not supported");
          return;
        } else if (
          this.format == "font" &&
          _validFileExtensions.indexOf(this.value.type) >= 0
        )
          return this.errorAlert("This file type is not supported");
        else if (
          this.value.size > 10000000 &&
          (this.format == "image" || this.format == "pdf")
        ) {
          this.errorAlert("This file size is too large");
          return;
        } else if (
          this.value.size > 50000000 &&
          (this.format == "video" || this.format == "all")
        ) {
          this.errorAlert("This file size is too large");
          return;
        } else if (this.value.size > 10000000 && this.format == "excel") {
          this.errorAlert("This file size is too large");
          return;
        }
      } else {
        this.value = "";
        this.$refs.input.value = "";
        this.hasError = true;
      }

      this.$emit(
        "onFileChange",
        this.value,
        this.indexGroup,
        this.indexCondition,
        this.data.condition_type_id
      );

      this.$refs.input.value = null;
      //this.hasImage = true;
    },
    downloadItem: async function (path) {
      let pathFile = path;
      this.axios({
        url: pathFile,
        method: "GET",
        headers: null,
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `download.${response.data.type.split("/").pop(-1)}`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    deleteImage() {
      this.$refs.file.value = "";
      this.$refs.input.value = "";
      this.$emit("delete", true);
      this.hasImage = false;
    },
  },
};
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.detail-format {
  color: #9b9b9b;
  font-size: 12px;
  font-family: "Kanit-Light";
  margin-top: 3px;
  margin-bottom: 0px;
}
.bg-icon.fa-lg {
  margin: 8px 0px;
}
.div-input {
  margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
}

input.custom-input {
  color: var(--font-main-color);
  background-color: white;
  border: 1px solid #bcbcbc;
  border-radius: 0px;
  padding: 7px 10px;
}
label[size="lg"].btn-main,
input[size="lg"].custom-input {
  height: 45px;
}
.input-custom.error .custom-input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: var(--font-main-color);
  font-size: 15px;
  font-family: "Kanit-Light";
}
.display-only {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.btn-main {
  width: 120px;
  text-align: center;
  height: 35px;
  vertical-align: middle;
  cursor: pointer;
  background: var(--font-main-color);
  color: white;
  padding: 7px 10px;
  border-radius: 0px 0.25rem 0.25rem 0px;
}
.btn-download-file {
  padding: 0;
  text-decoration: underline;
}
.delete-icon {
  position: absolute;
  right: 5%;
  top: 10px;
}
.text-error {
  color: red;
}
@media (max-width: 767.98px) {
  .detail-format {
    font-size: 11px;
  }
}
.input-custom input {
  color: var(--font-main-color);
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem 0 0 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  width: 100%;
}
.error input {
  border-color: red;
}
</style>
