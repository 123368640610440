<template>
  <div :class="[{ 'p-0': isCustomer }, 'mt-2 recent-card']">
    <!-- <hr v-if="isCustomer" /> -->
    <div class="d-flex justify-content-between align-items-center">
      <div :class="titleClass">
        {{ isCustomer ? "Ticket" : "Recent Ticket" }}
      </div>
      <div v-if="isCustomer">
        <div class="d-flex align-items-center column-gap-lg">
          <div class="d-flex align-items-center column-gap-lg">
            <span style="width: 200px">
              <InputTicketType
                :hideLabel="true"
                :value="filter.type"
                className="filter-ticket"
                @change="(val) => sendForm('type', val)"
              />
            </span>
            <span style="width: 200px">
              <InputStatus
                :hideLabel="true"
                :value="filter.status"
                className="filter-ticket"
                @change="(val) => sendForm('status', val)"
            /></span>
          </div>
          <b-button
            class="btn button btn-mobile mb-1"
            @click="$emit('addActivity')"
          >
            Create New
          </b-button>
        </div>
      </div>
    </div>
    <template v-if="isLoading">
      <!-- <OtherLoading /> -->
      <div class="text-center d-flex align-items-center justify-content-center">
        <b-spinner
          label="Loading..."
          variant="dark"
          class="m-5 spinner-main"
        ></b-spinner>
      </div>
    </template>
    <template v-else-if="items.length > 0">
      <div style="min-height: 450px">
        <div
          v-for="item in items"
          :key="item.id"
          class="activity-card"
          :class="[{ 'is-close': item.ticket_status_id == 4 }]"
        >
          <div class="activity-icon">
            <font-awesome-icon
              :icon="getActivityIcon(item.activity_source_id)"
              class="icon"
            />
            <hr class="my-2" />
          </div>
          <div class="activity-info">
            <div class="date">
              {{ $moment(item.created_time).format("DD/MM/YYYY HH:mm:ss") }}

              <font-awesome-icon
                v-if="item.deleted != 1"
                icon="edit"
                class="edit-icon"
                @click="
                  $router.push({
                    path: `/ticket-activity/detail/${item.id}${
                      isCustomer ? '?from=customer' : ''
                    }`,
                  })
                "
              />
            </div>
            <div class="name">#{{ item.id }} {{ item.name }}</div>
            <ul class="info">
              <li v-if="item.activity_source_type">
                {{ item.activity_source_type }}
              </li>
              <li>Status: {{ item.status }}</li>
              <li v-if="item.sla_status == 1 && item.ticket_status_id === 1">
                <span class="badge badge-new badge-pill">New</span>
              </li>
            </ul>
            <div class="type">{{ item.ticket_type }}</div>
          </div>
        </div>
      </div>
      <Pagination
        @handleChangeTake="handleChangeTake"
        @pagination="pagination"
        take="pageSize"
        :filter="payload"
        :rows="rows"
      />
    </template>

    <div class="activity-card-nodata" v-else-if="!items.length">
      <div>No recent tickets.</div>
    </div>
  </div>
</template>

<script>
import InputStatus from "@/components/ticket/activity/InputStatus";
import InputTicketType from "@/components/ticket/activity/InputTicketType";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: { OtherLoading, InputStatus, InputTicketType },
  props: {
    userId: {
      required: true,
      type: String,
    },
    isCustomer: {
      required: false,
      type: Boolean,
      default: false,
    },
    titleClass: {
      required: false,
      type: String,
      default: "title",
    },
    ticketId: {
      type: String,
      default: "0",
    },
    isDeleted: {
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      items: [],

      filter: {
        search: "",
        page: 1,
        take: 50,
        status: [],
        type: [],
      },
      payload: {
        current_ticket_id: this.ticketId,
        user_guid: this.userId,
        ticket_status_id: [], // list int
        page: 0,
        pageSize: 50,
        type: [],
      },
      rows: 0,
      isLoading: false,
      filterType: [],
    };
  },

  watch: {
    userId: {
      handler(newVal) {
        if (newVal) this.getList();
      },
      immediate: true,
    },
  },
  created() {
    this.getType();
  },
  methods: {
    getActivityIcon(id) {
      if (id == 33) return ["fab", "line"];
      else if (id == 34) return "phone-alt";
      return "phone";
    },
    getList: async function (filter) {
      this.isLoading = true;

      let payload = { ...this.payload };
      if (filter) {
        const { status, page, type } = filter;
        payload.page = page ? page : payload.page;
        payload.ticket_status_id = status.map((el) => el.id);
        payload.type = type.map((el) => el.id);
      }
      const { data } = await this.axios.post(
        `/ticket/activity/recent`,
        payload
      );
      if (data.result == 1) {
        this.items = data.detail.data || [];
        this.rows = data.detail.total;
      }
      this.isLoading = false;
    },
    handleChangeTake(val) {
      this.payload.pageSize = val;
      this.payload.page = 1;
      this.getList();
    },
    pagination(val) {
      this.payload.page = val;
      this.getList();
    },

    sendForm(key, val) {
      this.filter.page = 1;
      this.filter[key] = val;
      // this.$emit("afterSubmit", this.filter);
      this.getList(this.filter);
      // this.hide();
    },
    async getType() {
      const result = await this.axios("/ticket/filter/6");
      // console.log(result.data);
      this.filterType = result.data.detail;
    },
  },
};
</script>

<style lang="scss" scoped>
.recent-card {
  background-color: white;
  padding: 16px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  border-radius: 0.75rem;
  color: var(--main-font-color);

  .activity-card {
    display: flex;
    gap: 8px;

    .activity-icon {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      hr {
        height: 100%;
        border: 0.5px solid #eeeeee;
      }

      .icon {
        color: var(--primary-color);
        cursor: pointer;
        font-size: 24px;
      }
    }

    &.is-close {
      opacity: 0.5;
    }
  }

  .title {
    color: #888888;
  }

  .date {
    font-size: 12px;
    color: #bababa;
    display: flex;
    gap: 8px;
    align-items: center;

    .icon {
      color: var(--primary-color);
    }
  }

  .name {
    font-size: 16px;
  }

  .info {
    font-size: 12px;
    color: #bababa;
    display: flex;
    align-items: center;

    // &ul {
    padding-left: 0;
    margin-bottom: 0;

    li {
      display: inline-flex;
      color: #bababa !important;
      gap: 4px;
      margin-right: 8px;
      align-items: center;
    }

    li::before {
      content: "•";
      padding-right: 8px;
      color: #a2a2a2;
    }
    // }
  }

  .type {
    font-size: 14px;
  }
}

.activity-card-nodata {
  color: #888888;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  border-radius: 10px;
  opacity: 0.5;
}

.edit-icon {
  color: var(--primary-color);
  cursor: pointer;
  font-size: 18px;
}

.badge-new {
  background-color: rgb(39, 189, 167);
  color: white;
  padding: 0.4em 0.6em;
  font-size: 10px;
}
</style>
