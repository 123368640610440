<template>
  <div>
    <HeaderPanel
      :title="view ? '' : 'Ticket Template'"
      :filter="filter"
      placeholder="Search Name"
      @search="getList"
      @sidebar="sidebarFilter"
      :hasDropdown="false"
      :hasFilter="!view"
      :hideCreate="view"
      :routerPath="view ? '' : '/setting/ticket/template/0'"
    >
    </HeaderPanel>

    <div class="mt-3" :class="[!view ? 'bg-white border-red p-2 p-lg-3' : '']">
      <b-table
        responsive
        striped
        hover
        :fields="view ? fieldsView : fields"
        :items="items"
        :busy="isLoading"
        show-empty
        no-footer-sorting
        @sort-changed="onSorting"
        :sort-by.sync="filter.sortBy"
        no-local-sorting
        :sort-desc.sync="filter.sortDesc"
        empty-text="No matching records found"
      >
        <template v-slot:cell(id)="{ item }">
          <b-button
            @click="$emit('selectTemplate', item, templateId == item.id)"
            :variant="
              templateId == item.id
                ? 'custom-outline-primary'
                : 'custom-primary'
            "
            style="width: 80px"
          >
            {{ templateId == item.id ? "Selected" : "Select" }}
          </b-button>
        </template>
        <template v-slot:cell(nameView)="{ item }">
          <span class="text">{{ item.name }}</span>
        </template>
        <template v-slot:cell(name)="{ item }">
          <router-link :to="'/setting/ticket/template/' + item.id">
            <span class="text-underline">{{ item.name }}</span>
          </router-link>
        </template>

        <template v-slot:cell(active)="{ item }">
          <span :class="item.active ? 'text-success' : 'text-error'">{{
            item.active ? "Active" : "Inactive"
          }}</span>
        </template>
        <template v-slot:cell(action)="{ item }">
          <div class="d-flex justify-content-center align-items-center">
            <router-link :to="'/setting/ticket/template/' + item.id">
              <b-button variant="link" class="text-warning px-1 py-0">
                <font-awesome-icon icon="pencil-alt" title="Edit" />
              </b-button>
            </router-link>
          </div>
        </template>

        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        take="pageSize"
        @pagination="pagination"
      />
    </div>
    <SideBarFilter
      ref="filter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="getList"
      :hideSearchBar="true"
    >
    </SideBarFilter>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    view: {
      required: false,
      type: Boolean,
      default: false,
    },
    templateId: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: false,
      fieldsView: [
        {
          key: "id",
          label: "",
        },
        {
          key: "nameView",
          label: "Name",
        },
        {
          label: "Created Time",
          key: "created_time",
        },
      ],
      fields: [
        {
          key: "name",
          label: "Name",
          thClass: "w-2 ",
          tdClass: "text-left",
        },
        {
          label: "Created Time",
          key: "created_time",
          sortable: true,
          class: "w-1",
        },
        {
          label: "Sort Order",
          key: "sort_order",
          sortable: true,
          class: "w-1",
        },

        {
          key: "active",
          label: "Status",
          class: "w-1",
        },
        {
          key: "action",
          label: "Action",
          class: "w-1",
        },
      ],
      items: [],
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        status: [0, 1],
        page: 1,
        pageSize: 10,
        sortType: false,
        sortColumnId: 2,
        sortBy: "",
        sortDesc: "",
      },
    };
  },

  async mounted() {
    await this.getList();
  },
  watch: {},
  destroyed() {},
  methods: {
    clearValue() {},
    async getList() {
      this.isLoading = true;
      let url = "/ticket/template/list";
      if (this.view) url = "/ticket/template/filter/list";

      const res = await this.axios.post(url, this.filter);
      if (res.data.result === 1) {
        this.items = res.data.detail.template;
        this.rows = res.data.detail.total;
        this.isLoading = false;
      }
    },

    handleChangeTake(value) {
      this.filter.take = value;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },

    onSorting(ctx) {
      let index = this.fields.findIndex((el) => el.key == ctx.sortBy);
      index = index;

      if (
        this.filter.SortType &&
        !ctx.sortDesc &&
        this.filter.sortColumnId == index
      ) {
        this.filter.sortColumnId = 0;
        this.filter.sortColumnId = 0;
        this.filter.sortBy = "";
        this.filter.sortDesc = "";
      } else {
        this.filter.sortColumnId = index;
        this.filter.SortType = ctx.sortDesc;
      }
      this.getList();
    },
    clearFilter() {
      this.filter = {
        search: "",
        status: [0, 1],
        page: 1,
        pageSize: 10,
        sortType: true,
        sortColumnId: 0, // 1 = created_time, 2 = sort_order
        sortBy: "",
        sortDesc: "",
      };
      this.getList();
      this.$refs.filter.hide();
    },
    sidebarFilter() {
      this.$refs.filter.show();
    },
  },
};
</script>

<style scoped>
.icon-size {
  font-size: 20px;
  color: #575757;
}
</style>
