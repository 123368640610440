<template>
  <b-row>
    <b-col cols="12" sm="6">
      <div class="main-label">Profile Picture</div>
      <InputUploadFile
        id="profile-image"
        label="Profile Picture"
        :img="form.picture"
        v-model="form.picture"
        :ImageName="form.picture_file_name"
        textUpload="Upload Picture"
        @setFileName="(val) => (form.picture_file_name = val)"
        :dateTime="$moment()"
        @deleteImage="deleteImage('picture')"
      />
    </b-col>
    <b-col cols="12" sm="6">
      <div class="main-label">Identification Card Picture</div>

      <InputUploadFile
        id="id-card-image"
        label="Identification Card Profile"
        :img="form.id_card_image"
        v-model="form.id_card_image"
        :ImageName="form.id_card_file_name"
        :dateTime="$moment()"
        @setFileName="(val) => (form.id_card_file_name = val)"
        @deleteImage="deleteImage('id_card_image')"
      />
    </b-col>
    <b-col sm="6" class="mt-3">
      <div class="main-label">Company Information</div>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="company_information"
          v-model="form.company_information"
          :options="[
            { value: 'จดทะเบียน', text: 'Registered' },
            { value: 'ไม่จดทะเบียน', text: 'Not Registered' },
          ]"
          :aria-describedby="ariaDescribedby"
          @change="(val) => handleTypeChange(val, 27)"
          name="company_information-options"
        ></b-form-radio-group>
      </b-form-group>
    </b-col>
    <div class="break-normal"></div>
    <b-col cols="12" sm="6">
      <InputText
        :textFloat="
          form.company_information == 'จดทะเบียน'
            ? 'Business Reg No. (Tax ID.)'
            : 'Identification Number'
        "
        :placeholder="
          form.company_information == 'จดทะเบียน'
            ? 'Business Reg No. (Tax ID.)'
            : 'Identification Number'
        "
        type="number"
        name="Business Reg No. (Tax ID.)"
        :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 13);'"
        v-model="form.id_card"
        :v="v.form.id_card"
        :isValidate="v.form.id_card.$error"
      />
    </b-col>
    <div class="break-normal"></div>

    <b-col sm="12" md="6">
      <InputSelect
        title="Business Type"
        name="Business Type"
        :options="businessTypeOption"
        isRequired
        v-model="form.company_type"
        @onDataChange="(val) => handleTypeChange(val, 28)"
        :v="v.form.company_type"
        :isValidate="v.form.company_type.$error"
      >
        <!-- isRequired -->
        <template v-slot:option-first>
          <b-form-select-option value="" disabled>
            Please Select
          </b-form-select-option>
        </template>
      </InputSelect>
    </b-col>
    <div class="break-normal"></div>
    <b-col sm="12" md="6">
      <InputSelect
        title="Business Title"
        name="Business Title"
        :options="prefixOption"
        valueField="name"
        textField="name"
        isRequired
        v-model="form.prefix"
        :v="v.form.prefix"
        :isValidate="v.form.prefix.$error"
      >
        <!-- isRequired -->
        <template v-slot:option-first>
          <b-form-select-option value="" disabled>
            Please Select
          </b-form-select-option>
        </template>
      </InputSelect>
    </b-col>
    <b-col cols="12" md="6" v-if="checkOtherValue()">
      <InputText
        textFloat="Title"
        placeholder="Title"
        type="text"
        name="tax-id"
        v-model="form.prefix_other"
      />
    </b-col>
    <b-col sm="12" md="6">
      <InputText
        textFloat="Business Name"
        placeholder="Business Name"
        type="text"
        name="first_name_th"
        v-model="form.first_name_th"
        isRequired
        :v="v.form.first_name_th"
        :isValidate="v.form.first_name_th.$error"
      />
    </b-col>
    <div class="break-normal"></div>
    <b-col sm="6">
      <div class="main-label">Branch Type</div>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="company_branch_type"
          v-model="form.company_branch_type"
          :options="[
            { value: 'สำนักงานใหญ่', text: 'Head Office' },
            { value: 'สาขา', text: 'Branch' },
          ]"
          :aria-describedby="ariaDescribedby"
          name="company_branch_type"
          @change="changeBranchType"
        ></b-form-radio-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6" v-if="form.company_branch_type == 'สาขา'">
      <div class="d-flex" style="column-gap: 15px">
        <InputText
          textFloat="Branch Number"
          placeholder="Branch Number"
          type="text"
          name="Branch Number"
          isRequired
          v-model="form.company_branch_no"
          style="flex: 1"
          :v="v.form.company_branch_no"
          :isValidate="v.form.company_branch_no.$error"
        />
        <InputText
          textFloat="Branch Name"
          placeholder="Branch Name"
          type="text"
          name="Branch Name"
          isRequired
          v-model="form.company_branch_no"
          style="flex: 1"
          :v="v.form.company_branch_no"
          :isValidate="v.form.company_branch_no.$error"
        />
      </div>
    </b-col>
    <b-col cols="12">
      <InputText
        textFloat="Email"
        placeholder="Email"
        type="text"
        name="email"
        v-model="form.email"
        :v="v.form.email"
        :isValidate="v.form.email.$error"
      />
    </b-col>
    <b-col cols="6">
      <InputTelephoneWithCountry
        textFloat="Primary Telephone."
        placeholder="Primary Telephone."
        :country="form.telephone_country_id"
        @set="(val) => (form.telephone_country_id = val)"
        v-model="form.telephone"
        isRequired
        :v="v.form.telephone"
        :isValidate="v.form.telephone.$error"
      />
    </b-col>
    <b-col cols="6">
      <InputTag v-model="optionalTelephone" :limit="parseInt(limit)" />
    </b-col>
    <b-col cols="12"> <div class="label-header">Social Media</div></b-col>
    <template v-for="$social of socialList">
      <b-col cols="12" md="6" :key="$social.id">
        <InputText
          :textFloat="$social.name"
          :placeholder="$social.name"
          type="text"
          :name="$social.name"
          v-model="$social.value"
          :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 30);'"
        />
      </b-col>
    </template>
  </b-row>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import MultipleRadioButton from "@/components/inputs/MultipleRadioButton";
import InputTelephoneWithCountry from "@/components/inputs/InputTelephoneWithCountry";

import InputUploadFile from "@/components/inputs/InputUploadFile";

export default {
  components: {
    UploadFile,
    InputText,
    InputSelect,
    MultipleRadioButton,
    InputTelephoneWithCountry,
    InputUploadFile,
  },
  props: {
    formMain: {
      required: true,
      type: Object,
    },

    FieldListMain: {
      required: false,
      type: Array,
    },

    v: {
      required: false,
      type: Object,
    },
    limit: {
      required: false,
    },
    socialList: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isShowSidebar: false,
      image: "",
      acceptType: "image/jpeg, image/png",
      isLoadingImage: false,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      isLoadingImage: false,
      isDisable: false,
      prefixOption: [],
      businessTypeOption: [],
      genderChoice: [],
      optionalTelephone: [],
      form: {
        user_guid: this.$route.params.id,
        first_name_th: null,
        last_name_th: null,
        first_name_en: null,
        last_name_en: null,
        nickname: "",
        email: "",
        telephone: "",
        member_level_id: 0,
        picture: null,
        birthday: "",
        line_id: null,
        line_ref: null,
        home_address: null,
        gender: "",
        zip_code: null,
        province: null,
        town: null,
        alley: null,
        road: null,
        subdistrict: null,
        district: null,
        is_consent: 0,
        id_card: "",
        prefix: "",
        user_custom_field: [],
        user_tag: [],
        telephone_country_id: 1,
      },
      fieldList: [],
      errorOptional: false,
      customerTypeList: [],
    };
  },

  mounted() {},
  computed: {},
  methods: {
    async setFields() {
      if (this.fieldList.length > 0) {
        let data = JSON.parse(JSON.stringify(this.form));

        const prefix = await this.handleTypeChange(
          this.form.company_information,
          27,
          false
        );
        if (prefix) {
          let cpf = prefix.find((el) => el.name == this.form.company_type);
          if (cpf) {
            let pf = cpf.denpendent.map((el) => {
              return { ...el, name: el.choice_dependent_name };
            });

            this.prefixOption = pf;
          }
        }
      }
    },
    handlePrefix() {
      this.$nextTick(() => {
        let male = ["เด็กชาย", "นาย"];
        let Female = ["เด็กหญิง", "นาง", "นางสาว"];

        if (male.includes(this.form.prefix)) {
          this.form.gender = "Male";
        } else if (Female.includes(this.form.prefix)) {
          this.form.gender = "Female";
        } else this.form.gender = "N/A";
      });
    },
    async show() {
      this.v.form.$reset();
      this.fieldList = JSON.parse(JSON.stringify(this.FieldListMain));

      this.formMain.company_branch_no = "";
      this.formMain.company_branch_no = "";
      this.form = JSON.parse(JSON.stringify(this.formMain));

      this.optionalTelephone = this.formMain.optional_telephone
        .split(",")
        .filter((el) => el);
      await this.setFields();

      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.form.picture = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },

    deleteImage(key) {
      this.form[key] = "";
    },
    selectMemberLevel(value) {
      this.form.member_level_id = value;
    },

    async updateProfileDetail() {
      this.$bus.$emit("showLoading");
      let payload = { ...this.form };
      payload.expire_date = payload.expire_date ? payload.expire_date : null;
      payload.social = this.socialList.map((el) => {
        return {
          id: 0,
          social_media_type_id: el.id,
          value: el.value,
        };
      });
      payload.optional_telephone = this.optionalTelephone
        ? this.optionalTelephone.join(",")
        : "";
      if (payload.company_branch_type == "สาขา") {
        payload.company_branch_type = `${this.form.company_branch_no}*,${this.form.company_branch_no}`;
      }
      if (this.checkOtherValue()) payload.prefix = this.form.prefix_other;
      const result = await this.axios.post("/customer", payload);
      const data = result.data;

      this.$bus.$emit("hideLoading");
      if (data.result === 1) {
        this.successAlert().then(() =>
          this.$router.push("/report/customer/" + data.detail.id)
        );
        return true;
      } else {
        this.warningAlert(data.message);
        return false;
      }
    },
    handleChange(val) {
      this.form.gender = val;
    },

    validateOptional(val) {
      const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string);
      if (parseInt(this.form.limit_secondary_telephone) != 0) {
        if (
          this.optionalTelephone.length >= this.form.limit_secondary_telephone
        )
          return;
      }
      if (val.length == 10 && isNumeric(val)) {
        return val;
      }

      (this.errorOptional = true),
        setTimeout(() => {
          this.errorOptional = false;
        }, 3000);
      return;
    },

    async handleTypeChange(val, id, clear = true) {
      let field = this.fieldList.find((el) => el.field_profile_type_id == id);

      let payload = field.field_choices.find((el) => el.name == val);

      let denpendent = this.fieldList.findIndex(
        (el) => el.field_profile_type_id == field.field_dependent
      );

      if (payload)
        if (payload.denpendent.length > 0) {
          var temp = null;
          // this.$nextTick(async () => {
          temp = JSON.parse(JSON.stringify(this.fieldList));

          temp[denpendent].field_choices = await payload.denpendent.map(
            (el) => {
              return {
                ...el,
                name: el.choice_dependent_name,
                description: el.name,
                denpendent: el.level3,
                is_display: 1,
              };
            }
          );
          this.fieldList = await JSON.parse(JSON.stringify(temp));
          if (id == 27) {
            if (clear) {
              this.form.company_type = "";
              this.form.prefix = "";
            }
            this.businessTypeOption = payload.denpendent.map(
              (el) => el.choice_dependent_name
            );
          }
          if (id == 28) {
            if (clear) {
              this.form.prefix = "";
            }

            this.prefixOption = payload.denpendent.map((el) => {
              return { ...el, name: el.choice_dependent_name };
            });
          }
          return temp[denpendent].field_choices;
          // });
        }
    },
    checkOtherValue() {
      if (this.prefixOption.length > 0) {
        let check = this.prefixOption.find((el) => el.name == this.form.prefix);

        return check && check.choice_type_id == 3;
      }
      return false;
    },
    getForm() {
      return { ...this.formMain, ...this.form };
    },
    changeBranchType(val) {
      if (val == "สำนักงานใหญ่") {
        this.form.company_branch_no = "";
        this.form.company_branch = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 400px;
}
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
  font-size: 17px;
}
.panel-bg-file-img {
  width: 50%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  margin-bottom: 15px;
  position: relative;
}

.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
.title-label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.consent-allow {
  color: #06c306;
}
.consent-cancel {
  color: red;
}
.f-right {
  float: right;
}
.point-box {
  margin: 0px;
  padding: 10px 5px;
  background-color: var(--theme-secondary-color);
}
.icon-to-point {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  margin-left: 5px;
  font-size: 10px;
  border-radius: 3px;
  padding: 3px 9px;
}
.title-point {
  color: gray;
}
.title-total-point {
  font-weight: 600;
  font-size: 17px;
}
</style>
