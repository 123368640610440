<template>
  <div>
    <HeaderPanel
      title="Ticket Analysis"
      :filter="filter"
      @sidebar="sidebarFilter"
      :hasDropdown="false"
      :hasSearch="false"
      :hasExport="false"
    />
    <p class="my-2 f-16">
      {{
        filter.created_time_type == 0
          ? `${$moment(filter.createdDateFrom).format(
              "DD MMM YYYY"
            )} - ${$moment(filter.createdDateTo).format("DD MMM YYYY")}`
          : `${$displayFilterDate(filter.created_time_type)}`
      }}
    </p>

    <StatPanel
      :column="statColumn"
      :data="stat"
      :isLoading="statLoading"
      class="mt-3"
    />
    <div class="chart-bg">
      <b-container class="pt-3">
        <b-row class="chart-gap">
          <b-col md="12">
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <div class="mb-2 content-between">
                <div class="chart-title-name">Tickets Created By Date</div>
                <div class="d-flex align-items-center" style="column-gap: 5px">
                  <div
                    class="f-14 underline cursor-pointer"
                    :class="filter.TypeDateFilter == 3 ? '' : 'text-gray'"
                    @click="getChartDate(3)"
                  >
                    Day
                  </div>
                  <div
                    class="f-14 underline cursor-pointer"
                    :class="filter.TypeDateFilter == 2 ? '' : 'text-gray'"
                    @click="getChartDate(2)"
                  >
                    Month
                  </div>
                  <div
                    class="f-14 underline cursor-pointer"
                    :class="filter.TypeDateFilter == 1 ? '' : 'text-gray'"
                    @click="getChartDate(1)"
                  >
                    Year
                  </div>
                  <div
                    class="f-14 underline cursor-pointer"
                    @click="chartExport(4)"
                  >
                    Export
                  </div>
                </div>
              </div>
              <Chart
                id="ticket-date"
                :chartData="dateChart.data"
                text="Number Of Tickets"
                class="mt-auto"
                :label="dateChart.label"
                :hideLegend="false"
                type="bar"
                v-if="!dateChart.isLoading"
              />
              <template v-else>
                <ChartLoading :id="'loading-date'" type="bar" />
              </template>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <div class="mb-2 content-between">
                <div class="chart-title-name">Agent Actions</div>
                <div class="d-flex align-items-center" style="column-gap: 5px">
                  <div
                    class="f-14 underline cursor-pointer"
                    @click="chartExport(3)"
                  >
                    Export
                  </div>
                </div>
              </div>
              <b-table
                fixed
                striped
                hover
                :fields="fields"
                :items="items"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
                @sort-changed="sortingChanged"
                :sort-by.sync="filter.sortBy"
                no-local-sorting
                :sort-desc.sync="filter.sortDesc"
              >
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
              <Pagination
                @handleChangeTake="handleChangeTake"
                @pagination="pagination"
                :filter="filter"
                page="page"
                take="pageSize"
                :rows="rows"
                :limit="2"
              />
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <div class="mb-2 content-between">
                <div class="chart-title-name">Tickets Created By Source</div>
                <div class="d-flex align-items-center" style="column-gap: 5px">
                  <div
                    class="f-14 underline cursor-pointer"
                    @click="chartExport(1)"
                  >
                    Export
                  </div>
                </div>
              </div>
              <Chart
                id="ticket-source"
                :chartData="sourceChart.data"
                text="Number Of Tickets"
                class="mt-auto"
                :label="sourceChart.label"
                :hideLegend="false"
                type="bar"
                v-if="!sourceChart.isLoading"
              />
              <template v-else>
                <ChartLoading :id="'loading-source'" type="bar" />
              </template>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <div class="mb-2 content-between">
                <div class="chart-title-name">Tickets Created By Type</div>
                <div class="d-flex align-items-center" style="column-gap: 5px">
                  <div
                    class="f-14 underline cursor-pointer"
                    @click="chartExport(2)"
                  >
                    Export
                  </div>
                </div>
              </div>
              <Chart
                id="ticket-type"
                :chartData="typeChart.data"
                text="Number Of Tickets"
                class="mt-auto"
                :label="typeChart.label"
                :hideLegend="false"
                type="bar"
                v-if="!typeChart.isLoading"
              />
              <template v-else>
                <ChartLoading :id="'loading-type'" type="bar" />
              </template>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card class="shadow-sm border-none text-black chart-rounded">
              <div class="mb-2 content-between">
                <div class="chart-title-name">Resolution Time</div>
                <div class="d-flex align-items-center" style="column-gap: 5px">
                  <div
                    class="f-14 underline cursor-pointer"
                    @click="chartExport(3, true)"
                  >
                    Export
                  </div>
                </div>
              </div>
              <Chart
                id="resolve"
                :chartData="resolveTime.data"
                text="Number Of Tickets"
                class="mt-auto"
                :label="resolveTime.label"
                type="bar"
                :hideLegend="false"
                v-if="!resolveTime.isLoading" />
              <template v-else>
                <ChartLoading :id="'loading-resolve'" type="bar" /> </template
            ></b-card>
          </b-col> </b-row
      ></b-container>
    </div>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <div class="filter">
          <InputSelect
            title="Create Date"
            class="mb-0"
            name="created_time_type"
            v-model="filter.created_time_type"
            v-bind:options="timeOptions"
            valueField="id"
            textField="name"
          />

          <template v-if="filter.created_time_type == 0">
            <div>
              <div class="mb-1">Create Date From</div>

              <div :class="['input-container']">
                <datetime
                  type="date"
                  class="date-picker"
                  placeholder="Please Select Date"
                  format="dd/MM/yyyy"
                  value-zone="local"
                  ref="createdDateFrom"
                  v-model="filter.createdDateFrom"
                >
                </datetime>

                <div
                  :class="'icon-primary text-right'"
                  @click="$refs.createdDateFrom.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    :class="'cursor-default color-primary'"
                    color="#B41BB4"
                  />
                </div>
              </div>
            </div>

            <div>
              <div class="mb-1">Create Date To</div>
              <div class="input-container">
                <datetime
                  type="date"
                  class="date-picker"
                  placeholder="Please Select Date"
                  format="dd/MM/yyyy"
                  value-zone="local"
                  ref="createdDateTo"
                  v-model="filter.createdDateTo"
                >
                </datetime>
                <div
                  :class="'icon-primary text-right'"
                  @click="$refs.createdDateTo.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    :class="'cursor-default color-primary'"
                    color="#B41BB4"
                  />
                </div>
              </div>
            </div>
          </template>
          <InputTicketType
            title="Topic Type"
            :value="filter.type"
            @change="filter.type = $event"
          />

          <InputAdminName
            title="Agent"
            :value="filter.admin_user_guid"
            @change="filter.admin_user_guid = $event"
          />
          <b-form-group label="Customer Type" class="mb-0">
            <b-form-checkbox-group
              v-model="filter.user_type"
              :options="[
                { id: 0, name: 'Personal' },
                { id: 1, name: 'Company' },
              ]"
              value-field="id"
              text-field="name"
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
          <InputCustomerType
            :value="filter.customer_type"
            @change="filter.customer_type = $event"
          />
          <check-box-anonymous
            :value="filter.allow_anonymous"
            @change="(val) => (filter.allow_anonymous = val)"
            class="mb-3"
          />
        </div>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
import Chart from "chart";
import ChartLoading from "@/components/loading/ChartLoading";
import InputAdminName from "@/components/ticket/activity/InputAdminName";
import InputTicketType from "@/components/ticket/activity/InputTicketType";
import InputCustomerName from "@/components/ticket/activity/InputCustomerName";
import InputCustomerType from "@/components/ticket/activity/InputCustomerType";
import InputSelect from "@/components/inputs/InputSelect";
export default {
  components: {
    Chart,
    InputTicketType,
    InputCustomerName,
    InputCustomerType,
    InputAdminName,
    InputSelect,
    ChartLoading,
  },
  data() {
    return {
      filter: {
        created_time_type: 3, // 1 = this week, 2 = this month, 3 = this year, 4 = all
        createdDateFrom: this.$moment().startOf("year").format(), // type datetime
        createdDateTo: this.$moment().format(), // type datetime// type datetime
        type: [], // list int
        admin_user_guid: [], //list user_guid (admin)
        customer_type: [], // list int
        user_type: [0, 1], // 0 = personal, 1 = company
        page: 1,
        pageSize: 10,
        sortType: true, // true = ASC, false = DESC
        sortColumnId: 0, // 1 = admin name, 2 = comment, 3 = create, 4 = resolved
        reportType: 0, // 1 = sourtce type, 2 = ticket type
        sortBy: "",
        sortDesc: "",
        TypeDateFilter: 1,
        allow_anonymous: this.$store.state.filter.allow_anonymous,
      },
      statColumn: [
        {
          name: "Total Tickets",
          key: "total",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "Open Tickets",
          key: "open",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "In Progress Tickets",
          key: "in_progress",
          isPercent: false,
          isDecimal: false,
        },

        {
          name: "Resolved Tickets",
          key: "resolved",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "Close Tickets",
          key: "close",
          isPercent: false,
          isDecimal: false,
        },
      ],
      stat: {
        total: 0,
        resolved: 0,
        unresolved: 0,
      },
      fields: [
        {
          key: "admin_name",
          label: "Agent Name",
          class: "w-2",
          sortable: true,
          text: "Total",
        },
        {
          key: "total_comment",
          label: "Total Comment",
          thClass: "w-5",
          sortable: true,
        },
        {
          key: "total_created",
          label: "Ticket Created",
          class: "w-2",
          sortable: true,
        },

        {
          key: "total_resolved",
          label: "Ticket Resolved",
          class: "w-2",
          sortable: true,
        },
      ],
      timeOptions: [
        { id: 1, name: "This Week" },
        { id: 2, name: "This Month" },
        { id: 3, name: "This Year" },
        { id: 0, name: "Custom" },
      ],
      rows: 0,
      isBusy: false,
      statLoading: false,
      items: [],
      dateChart: {
        isLoading: true,
        data: [],
        label: [],
      },
      sourceChart: {
        isLoading: true,
        data: [],
        label: [],
      },
      typeChart: {
        isLoading: true,
        data: [],
        label: [],
      },
      resolveTime: {
        isLoading: true,
        data: [],
        label: [],
      },
    };
  },
  created() {
    this.getData();
    this.getTable();
  },
  methods: {
    async getData() {
      let payload = { ...this.filter };
      payload.admin_user_guid =
        payload.admin_user_guid.length > 0
          ? payload.admin_user_guid.map((el) => el.user_guid)
          : [];
      payload.customer_type =
        payload.customer_type.length > 0
          ? payload.customer_type.map((el) => el.id)
          : [];
      payload.type =
        payload.type.length > 0 ? payload.type.map((el) => el.id) : [];
      this.sourceChart.isLoading = true;
      this.dateChart.isLoading = true;
      this.typeChart.isLoading = true;
      this.resolveTime.isLoading = true;
      const res = await this.$report.post("/ticket/default", payload);
      this.stat = res.data.detail.analysis;
      this.dateChart = {
        isLoading: false,
        label: await res.data.detail.ticketDate.map((el) => el.filter_date),
        data: [
          {
            label: "Open",
            type: "bar",
            data: await res.data.detail.ticketDate.map((el) => el.open),
            borderColor: await this.$store.dispatch("getChartColor", 0),
            backgroundColor: await this.$store.dispatch("getChartColor", 0),
            fill: false,
          },
          {
            label: "Resolve/Close",
            type: "bar",
            data: await res.data.detail.ticketDate.map((el) => el.close),
            borderColor: await this.$store.dispatch("getChartColor", {
              fade: true,
              index: 0,
            }),
            backgroundColor: await this.$store.dispatch("getChartColor", {
              fade: true,
              index: 0,
            }),
            fill: false,
          },
        ],
      };
      this.sourceChart = {
        isLoading: false,
        label: await res.data.detail.sourceType.map((el) => el.group),
        data: [
          {
            label: "Number Of Tickets",
            type: "bar",
            data: await res.data.detail.sourceType.map((el) => el.data),
            borderColor: await this.$store.dispatch("getChartColor", 0),
            backgroundColor: await this.$store.dispatch("getChartColor", 0),
            fill: false,
          },
        ],
      };
      this.typeChart = {
        isLoading: false,
        label: await res.data.detail.ticketType.map((el) => el.group),
        data: [
          {
            label: "Number Of Tickets",
            type: "bar",
            data: await res.data.detail.ticketType.map((el) => el.data),
            borderColor: await this.$store.dispatch("getChartColor", 0),
            backgroundColor: await this.$store.dispatch("getChartColor", 0),
            fill: false,
          },
        ],
      };
      this.resolveTime = {
        isLoading: false,
        label: await res.data.detail.ticketResolutionTime.map((el) => el.group),
        data: [
          {
            label: "Number Of Tickets",
            type: "bar",
            data: await res.data.detail.ticketResolutionTime.map(
              (el) => el.data
            ),
            borderColor: await this.$store.dispatch("getChartColor", 0),
            backgroundColor: await this.$store.dispatch("getChartColor", 0),
            fill: false,
          },
        ],
      };
    },
    async getTable() {
      this.isBusy = true;
      let payload = { ...this.filter };
      payload.admin_user_guid =
        payload.admin_user_guid.length > 0
          ? payload.admin_user_guid.map((el) => el.user_guid)
          : [];
      payload.customer_type =
        payload.customer_type.length > 0
          ? payload.customer_type.map((el) => el.id)
          : [];
      payload.type =
        payload.type.length > 0 ? payload.type.map((el) => el.id) : [];
      const res = await this.$report.post("/ticket/admin", payload);
      this.items = res.data.detail.data;
      this.rows = res.data.detail.total;
      this.isBusy = false;
    },
    handleChangeTake(val) {
      this.filter.page = 1;
      this.filter.pageSize = val;
      this.getTable();
    },
    pagination(val) {
      this.filter.page = val;
      this.getTable();
    },
    filterActions() {
      this.getData();
      this.getTable();
    },
    clearFilter() {
      this.filter = {
        created_time_type: 3, // 1 = this week, 2 = this month, 3 = this year, 4 = all
        createdDateFrom: this.$moment().startOf("year").format(), // type datetime
        createdDateTo: this.$moment().format(), // type datetime
        type: [], // list int
        admin_user_guid: [], //list user_guid (admin)
        customer_type: [], // list int
        user_type: [0, 1], // 0 = personal, 1 = company
        page: 1,
        pageSize: 10,
        sortType: true, // true = ASC, false = DESC
        sortColumnId: 0, // 1 = admin name, 2 = comment, 3 = create, 4 = resolved
        reportType: 0, // 1 = sourtce type, 2 = ticket type
        allow_anonymous: this.$store.state.filter.allow_anonymous,
      };
      this.$refs.sidebarFilter.hide();
      this.getTable();
      this.getData();
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    sortingChanged(ctx) {
      let index = this.fields.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.filter.SortType &&
        !ctx.sortDesc &&
        this.filter.SortColumnId == index
      ) {
        this.filter.SortColumnId = 0;
        this.filter.SortColumnId = 0;
        this.filter.sortBy = "";
        this.filter.sortDesc = "";
      } else {
        this.filter.SortColumnId = index;
        this.filter.SortType = ctx.sortDesc;
      }
      this.getTable();
    },
    async chartExport(val, type) {
      try {
        this.$bus.$emit("showLoading");
        let payload = { ...this.filter, reportType: val };
        var res = null;
        if (val == 3 && !type) {
          res = await this.$report.post("ticket/admin/report", payload, {
            responseType: "blob",
          });
        } else {
          res = await this.$report.post("/ticket/export/report", payload, {
            responseType: "blob",
          });
        }
        let data = res;
        let name =
          val == 1
            ? "ticket-source"
            : val == 3 && !type
            ? "agent"
            : val == 3 && type
            ? "resolution-time"
            : "ticket-type";
        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", name + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.$bus.$emit("hideLoading");
      } catch (error) {
        console.error(error);
        this.errorAlert(error.message);
        this.$bus.$emit("hideLoading");
      }
    },
    async getChartDate(val) {
      this.filter.TypeDateFilter = val;
      this.dateChart.isLoading = true;
      const resp = await this.$report.post("/ticket/graph/date", this.filter);
      if (resp.data.detail.length > 0)
        this.dateChart = {
          isLoading: false,
          label: await resp.data.detail.map((el) => el.filter_date),
          data: [
            {
              label: "Open",
              type: "bar",
              data: await resp.data.detail.map((el) => el.open),
              borderColor: await this.$store.dispatch("getChartColor", 0),
              backgroundColor: await this.$store.dispatch("getChartColor", 0),
              fill: false,
            },
            {
              label: "Close",
              type: "bar",
              data: await resp.data.detail.map((el) => el.close),
              borderColor: await this.$store.dispatch("getChartColor", {
                fade: true,
                index: 0,
              }),
              backgroundColor: await this.$store.dispatch("getChartColor", {
                fade: true,
                index: 0,
              }),
              fill: false,
            },
          ],
        };
      else {
        this.dateChart = {
          isLoading: false,
          label: [],
          data: [
            {
              label: "Open",
              type: "bar",
              data: [],
              borderColor: [],
              backgroundColor: [],
              fill: false,
            },
            {
              label: "Close",
              type: "bar",
              data: [],
              borderColor: [],
              backgroundColor: [],
              fill: false,
            },
          ],
        };
      }
    },
  },
};
</script>

<style>
.filter {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
</style>
