<template>
  <div>
    <HeaderPanel
      title="RECEIPT FORMAT"
      :filter="filter"
      placeholder=""
      @search="getList"
      :hasDropdown="false"
      :hasFilter="false"
      :hasSearch="false"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row class="align-items-center">
        <b-col cols="6">
          <InputSelect
            title="Tax Invoice (ABB) Format"
            name="abb_format"
            :options="formatOptions.tax_invoice_abb_format"
            v-model="form.tax_invoice_abb_format"
            valueField="id"
            textField="name"
            @onDataChange="(val) => onChange(val, 1)"
          />
        </b-col>
        <b-col cols="6">
          <span
            class="text-underline cursor-pointer"
            @click="showImage(1)"
            v-if="form.preview_image"
          >
            Preview
          </span>
        </b-col>
        <b-col cols="6">
          <InputSelect
            title="Tax Invoice Format"
            name="format"
            :options="formatOptions.tax_invoice_format"
            v-model="form.tax_invoice_format"
            valueField="id"
            textField="name"
            @onDataChange="(val) => onChange(val, 2)"
        /></b-col>
        <b-col cols="6">
          <span
            class="text-underline cursor-pointer"
            @click="showImage(2)"
            v-if="form.preview_image_abb"
          >
            Preview
          </span>
        </b-col>
      </b-row>
    </div>
    <CardOverLay
      :img="images"
      @close="overlay = false"
      v-if="overlay"
      :type="images.includes('.pdf') ? 'pdf' : 'image'"
    />
    <FooterAction routePath="/setting" @submit="submit()" />
  </div>
</template>
<script>
import CardOverLay from "@/components/report/customer/detail/components/CardOverLay";
export default {
  name: "RECEIPT_IMAGE_FORMAT",
  components: {
    CardOverLay,
  },
  data() {
    return {
      value: "",
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,

      items: [],
      formatOptions: { tax_invoice_abb_format: [], tax_invoice_format: [] },
      form: {
        tax_invoice_abb_format: 0,
        tax_invoice_format: 0,
        preview_image_abb: "",
        preview_image: "",
      },
      images: "",
      overlay: false,
    };
  },
  created() {
    this.getList();
  },

  methods: {
    async getList() {
      const response = await this.axios(`/setting/taxinvoice`);
      this.getOptions(1);
      this.getOptions(2);
      this.form = response.data.detail;
    },
    async getOptions(type) {
      this.isBusy = true;
      const response = await this.axios(`/setting/taxinvoice/${type}`);
      let key = type == 1 ? "tax_invoice_format" : "tax_invoice_abb_format";
      let imageKey = type == 1 ? "preview_image" : "preview_image_abb";
      this.formatOptions[key] = response.data.detail;
      this.form[imageKey] = response.data.detail[0].image_url;
    },
    onChange(val, type) {},
    showImage(type) {
      let imageKey = type == 1 ? "preview_image" : "preview_image_abb";
      this.overlay = true;
      this.image = this.form[imageKey];
    },
    async submit() {
      try {
        this.$bus.$emit("showLoading");
        const response = await this.axios.post(
          `/setting/taxinvoice`,
          this.form
        );
        this.$bus.$emit("hideLoading");
        if (response.data.result)
          this.successAlert().then(() => this.$router.push("/setting"));
        else this.errorAlert(response.data.message);
      } catch (error) {
        this.$bus.$emit("hideLoading");
        this.errorAlert(error.message);
      }
    },
  },
};
</script>
