import { render, staticRenderFns } from "./UploadFileV2.vue?vue&type=template&id=5beb035a&scoped=true"
import script from "./UploadFileV2.vue?vue&type=script&lang=js"
export * from "./UploadFileV2.vue?vue&type=script&lang=js"
import style0 from "./UploadFileV2.vue?vue&type=style&index=0&id=5beb035a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5beb035a",
  null
  
)

export default component.exports